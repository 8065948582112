import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

export class AddCarrera extends Component {
  static displayName = AddCarrera.name;

  constructor(props) {
    super(props);
    this.state = { loading: false, anioImplementacion: new Date().getFullYear().toString(), name: "", showErrorMessage: false, success: false, errorMessage: "", resolucionMinisterial: "" };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleAnioImplementacion = this.handleAnioImplementacion.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleResolucionMinisterial = this.handleResolucionMinisterial.bind(this);
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveCarrera();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWCarreras');
  }

  handleAnioImplementacion = e => {
    this.setState({ anioImplementacion: e.target.value });
  }

  handleResolucionMinisterial = e => {
    this.setState({ resolucionMinisterial: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nueva Carrera</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Nombre Completo: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre Completo"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading}
                required
              />
            </div>
            <div className="pa2">
              <label> Año de Implementación: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese Año Implementación"
                onChange={this.handleAnioImplementacion}
                disabled={this.state.loading}
                value={this.state.anioImplementacion}
              />
            </div>
            <div className="pa2">
              <label> Resolución Ministerial: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Resolución de Aprobación"
                onChange={this.handleResolucionMinisterial}
                disabled={this.state.loading}
                required
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-carreras"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-carreras" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.anioImplementacion.length > 0 && this.state.name.length > 0 && this.state.resolucionMinisterial.length > 0);
  }

  async saveCarrera() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Carreras/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          CarreraNombre: this.state.name,
          CarreraAnioImplementacion: this.state.anioImplementacion,
          CarreraResolucionAprobacion: this.state.resolucionMinisterial,
          CarreraExtinta: 'N'
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else  if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
