import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import { Redirect } from 'react-router';
import * as constantClass from '../fileWithConstants';
import Select from 'react-select';
import { LookupCarreras } from '../Carreras/LookupCarreras';

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: '1', label: 'Primer Cuatrimestre' },
  { value: '2', label: 'Segundo Cuatrimestre' },
  { value: 'A', label: 'Anual' },
];

const options = [
  { value: '', label: '' },
  { value: 'S', label: 'Si' },
  { value: 'N', label: 'No' }
];

export class UpdateMateria extends Component {
  static displayName = UpdateMateria.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      carrera: { carreraCod: 0, carreraNombre: "" },
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      practica: "",
      valueDictado: [{ value: '', label: '' }],
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleAnioChangeInput = this.handleAnioChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleCodeChangeInput = this.handleCodeChangeInput.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWMaterias');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      if (this.isOkToSave()) {
        this.saveStudent();
      } else {
        this.setState({ showErrorMessage: true, errorMessage: "Los campos Alumno y Carrera son obligatorios" });
      }
    }
  }

  handleAnioChangeInput = e => {
    this.setState({ anioDictado: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleCodeChangeInput = e => {
    this.setState({ code: e.target.value });
  }

  handleHideLookup = () =>
  {
    this.setState({showLookupCarreras:false});
  }

  handleShowStudentLookup = () => {
    this.setState({ showLookupStudent: true });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupCarreras: true });
  }

  handleSelect = e => {
    this.setState({ practica: e.value, valuePractica: e });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado: e });
  }

  handleSelectStudentItem = (e) => {
    const studentProperties = e.split('%');
    this.setState(
      {
        alumno: {
          alumnoDni: studentProperties[0],
          alumnoNombre: studentProperties[1],
          alumnoCodigo: parseInt(studentProperties[2])
        },
        showLookupStudent: false
      });
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      carrera: {
        carreraCod: parseInt(carreraProperties[0]),
        carreraNombre: carreraProperties[1]
      }
    });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Materia" : "Modificar Materia";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Código Materia: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese Código"
                value={this.state.code}
                onChange={this.handleCodeChangeInput}
                disabled={this.state.loading || disable}
                required
              />
            </div>
            <div className="pa2">
              <label> Nombre Materia: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading || disable}
                value={ this.state.name}
                required
              />
            </div>
            <div className="pa2">
              <label> Año Dictado: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="10"
                placeholder="Ingrese Año"
                onChange={this.handleAnioChangeInput}
                disabled={this.state.loading || disable}
                value={ this.state.anioDictado}
                required
              />
            </div>
            <div>
              <label> Período de Dictado: </label>
              {disable ?
                <div className="dropdownDictado">
                  <label> {this.state.valueDictado[0].value == '1' ? 'Primer Cuatrimestre' : this.state.valueDictado[0].value == '2'? 'Segundo Cuatrimestre':'Anual'}</label>
                </div> :
                <div className="dropdownDictado">
                  <Select
                    value={this.state.valueDictado}
                    options={optionsCuatrimestre}
                    onChange={this.handleDictadoChangeInput}
                  />
                </div>
              }              
            </div>
            <div>
              {!disable ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la carrera</Button> :
                <label> Datos de la carrera </label>}
              {this.state.showLookupCarreras ? <LookupCarreras selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.carrera.carreraNombre}
              />
            </div>
            <div>
              <label> Materia Práctica: </label>
              {disable ?
                <div className="label">
                  <label> {this.state.practica == 'N' ? 'No' : 'Si'}</label>
                </div> :
                <div className="dropdown">
                  <Select
                    value={this.state.valuePractica}
                    options={options}
                    onChange={this.handleSelect}
                  />
                </div>
              }
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable? (
                <Button size="sm" tag={Link} to={"/md-mxc"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-mxc" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.anioDictado.length > 0 && this.state.carrera.carreraNombre.length > 0 && this.state.name.length > 0 && this.state.code.length > 0 && this.state.dictado.length > 0 && this.state.practica.length > 0);
  }

  async populateData() {
    const response = await fetch('api/mxc/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    var defaultValuePractica = options.filter(x => x.value.toString() === data.returnModel.materiaPractica);
    var defaultValueAnioDictado = optionsCuatrimestre.filter(x => x.value.toString() === data.returnModel.materiaCarreraDictado);
    this.setState(
      {
        errorMessage: errorMessage,
        carrera: {
          carreraNombre: data.returnModel.carreraNombre.trim(),
          carreraCodigo: data.returnModel.carreraCod
        },
        code: data.returnModel.materiaCarreraCodigo,
        name: data.returnModel.materiaCarreraNombre.trim(),
        anioDictado: data.returnModel.materiaCarreraAnio.trim(),
        dictado: data.returnModel.materiaCarreraDictado,
        practica: data.returnModel.materiaPractica,
        valuePractica : defaultValuePractica,
        valueDictado : defaultValueAnioDictado
      });
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Materia',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          MateriaCarreraCod: this.state.id,
          MateriaCarreraNombre: this.state.name,
          MateriaCarreraDictado: this.state.dictado,
          MateriaCarreraAnio: this.state.anioDictado,
          MateriaPractica: this.state.practica,
          CarreraCod: this.state.carrera.carreraCodigo,
          MateriaCarreraCodigo: this.state.code
        })
      });
    var data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
