import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupMaterias } from '../MateriaCarrera/LookupMaterias';
import { LookupPeriodoCursada } from '../PeriodoCursada/LookupPeriodoCursada';

export class UpdateCursada extends Component {
  static displayName = UpdateCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      carrera: { carreraCod: "", carreraNombre: "" },
      periodoCursada: { codigo: 0, descripcion: "" },
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      practica: "",
      showLookupStudent: false,
      showLookupCarreras: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStudentHideLookup = this.handleStudentHideLookup.bind(this);
    this.handleShowStudentLookup = this.handleShowStudentLookup.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleSelectStudentItem = this.handleSelectStudentItem.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWCursada');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      var okToSave = this.isOkToSave();
      var dictadoCorrecto = this.state.carrera.dictado == this.state.periodoCursada.dictado;

      if (okToSave && dictadoCorrecto) {
        this.saveStudent();
      } else {
        var errorMessage = "";
        if (!okToSave) {
          errorMessage = "Todos los campos son obligatorios";
        } else {
          errorMessage = "La materia seleccionada no se dicta en el período seleccionado";
        }
        this.setState({ showErrorMessage: true, errorMessage: errorMessage });
      }
    }
  }

  handleHideLookup = () => {
    this.setState({ showLookupCarreras: false });
  }

  handleStudentHideLookup = () => {
    this.setState({ showLookupStudent: false });
  }

  handleShowStudentLookup = () => {
    this.setState({ showLookupStudent: true });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupCarreras: true });
  }

  handleSelectStudentItem = (e) => {
    const studentProperties = e.split('%');
    this.setState(
      {
        periodoCursada: {
          codigo: studentProperties[0],
          descripcion: studentProperties[1] + "-" + studentProperties[2],
          dictado: studentProperties[3].trim(),
        },        
        showLookupStudent: false
      });
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      carrera: {
        carreraCodigo: carreraProperties[0],
        carreraNombre: carreraProperties[1] + "-" + carreraProperties[2],
        dictado: carreraProperties[4].trim(),
      }
    });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Cursada" : "Modificar Cursada";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>


            <div>
              {!disable ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la Materia</Button> :
                <label> Datos de la Materia </label>}
              {this.state.showLookupCarreras ? <LookupMaterias selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.carrera.carreraNombre}
              />
            </div>

            <div>
              {!disable ? <Button color="link" onClick={this.handleShowStudentLookup}> Seleccione el Período de Cursada</Button> :
                <label> Datos de la Cursada </label>}
              {this.state.showLookupStudent ? <LookupPeriodoCursada selectItem={this.handleSelectStudentItem} hideLookup={this.handleStudentHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.periodoCursada.descripcion}
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-cur"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-cur" />}
      </div>
    );
  }

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "1") {
      return "Primer Cuatrimestre";
    } else {
      if (periodoCursadaDescripcion == "2") {
        return "Segundo Cuatrimestre";
      }
      else {
        return "Anual";
      }
    }
  }

  isOkToSave() {
    return (this.state.carrera.carreraNombre.length > 0 && this.state.periodoCursada.descripcion.length > 0);
  }

  async populateData() {
    const response = await fetch('api/cur/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    this.setState(
      {
        errorMessage: errorMessage,
        carrera: {
          carreraNombre: data.returnModel.materiaCarreraCod + "-" + data.returnModel.materiaNombre,
          carreraCodigo: data.returnModel.materiaCarreraCod,
          dictado: data.returnModel.materiaDictado
        },
        periodoCursada: {
          codigo: data.returnModel.periodoCursadaCod,
          descripcion: this.getPeriodoCursada(data.returnModel.periodoCursadaDescripcion) + "-" + data.returnModel.anioAcademicoDescripcion,
          dictado: data.returnModel.periodoCursadaDescripcion
        }
      });
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/cur',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': constantClass.AuthorizationHeader,
        },
        body: JSON.stringify({
          CursadaCod: parseInt(this.state.id),
          PeriodoCursadaCod: parseInt(this.state.periodoCursada.codigo),
          MateriaCarreraCod: this.state.carrera.carreraCodigo.trim()
        })
      });
    var data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
