import React, { Component } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Container, Navbar } from 'reactstrap';
import './NavMenu.css';
import Cookies from 'js-cookie';

export class NavMenuAlumno extends Component {
  static displayName = NavMenuAlumno.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <div style={{ display: 'flex' }}>
              <NavDropdown className="text-primary my-auto" title="Usuario">
                {/* Cambiar Contraseña */}
                <NavDropdown.Item
                  style={{ width: '5vw' }}
                  onClick={() => {
                    Cookies.set('authToken', false);
                    Cookies.set('alumnoCodigos', "");
                    window.dispatchEvent(new CustomEvent('authenticated'));
                  }}
                >
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown className="text-primary my-auto" title="Cursadas">
                <NavDropdown.Item href="/md-ica">
                  Manejar datos de Cursadas
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Container>
        </Navbar>
      </header>
    );
  }
}
