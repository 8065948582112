import { format } from "date-fns";
import es from 'date-fns/locale/es';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

registerLocale('es', es)

const options = [
  { value: '', label: 'Seleccione una opción' },
  { value: '0', label: 'Ausente' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
];

export class UpdateHistoriaAcademicaExamen extends Component {
  static displayName = UpdateHistoriaAcademicaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      tagData: [{ id: "", text: "" }],
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate: new Date(),
      llamado: "",
      anio: { anioAcademicoCod: 0, anioAcademicoDescripcion: "", carrera: "" },
      materia: { codigo: 0, anioAcademicoDescripcion: "" },
      id: this.props.match.params.id,
      details: this.props.match.params.details,
      fecha: new Date(),
      fechaFinRegularidad: new Date(),
      observaciones: ""
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEndInscripcionDate = this.handleEndInscripcionDate.bind(this);
    this.handleStartInscriptionDate = this.handleStartInscriptionDate.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleNota = this.handleNota.bind(this);
    this.handleFechaExamen = this.handleFechaExamen.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('HistoriaAcademicaExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true });
    } else {
      this.saveStudent();
    }
  }

  async populateData() {
    const response = await fetch('api/hex/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data === "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data === "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;

    }
    var defaultValueAnioDictado = options.filter(x => x.value.toString() === data.returnModel.historiaAcademicaExamenNota.toString());
    this.setState(
      {
        errorMessage: errorMessage,
        anio: {
          anioAcademicoCod: data.returnModel.alumnoxCarreraCod,
          anioAcademicoDescripcion: data.returnModel.alumnoxCarreraCodNavigation.alumnoNombre,
          carrera: data.returnModel.alumnoxCarreraCodNavigation.carreraNombre
        },
        materia: {
          codigo: data.returnModel.materiaCarreraCod,
          anioAcademicoDescripcion: data.returnModel.materiaCarreraCodNavigation.materiaCarreraNombre
        },
        resultado: data.returnModel.historiaAcademicaExamenResulta.trim(),
        fecha: new Date(data.returnModel.historiaAcademicaFechaExamen),
        observaciones: data.returnModel.historiaAcademicaExamenNota,
        valueDictado: defaultValueAnioDictado
      });
  }

  handleEndInscripcionDate = (date) => {
    this.setState({ endInscriptionDate: date });
  }

  handleStartInscriptionDate = (date) => {
    this.setState({ startInscriptionDate: date });
  }

  handleStartDate = (date) => {
    this.setState({ fechaFinRegularidad: date });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ observaciones: e.value, valueDictado: e, resultado: e.value === "0" ? "Ausente" : e.value >= "4" ? "Aprobó" : "Reprobó" });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleFechaExamen = (date) => {
    this.setState({ fecha: date });
  }


  handleSelectAnioItem = (e) => {
    const anioProperties = e.split('%');
    this.setState({
      anio: {
        anioAcademicoCod: anioProperties[0],
        anioAcademicoDescripcion: anioProperties[1]
      }
    });
  }

  handleChange = (e) => {
    this.setState({ descripcion: e.value });
  }

  handleNameChangeInput = e => {
    this.setState({ observaciones: e.target.value });
  }

  handleNota = (nota) => {
    switch (nota) {
      case 1:
        return '1(uno)';
      case 2:
        return '2(dos)';
      case 3:
        return '3(tres)';
      case 4:
        return '4(cuatro)';
      case 5:
        return '5(cinco)';
      case 6:
        return '6(seis)';
      case 7:
        return '7(siete)';
      case 8:
        return '8(ocho)';
      case 9:
        return '9(nueve)';
      case 10:
        return '10(diez)';
      default:
        return ''
    }
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Historia Académica Examen" : "Modificar Historia Académica Examen";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <div>
                <label>Alumno:</label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  placeholder=""
                  disabled={true}
                  value={this.state.anio.anioAcademicoDescripcion + " - " + this.state.anio.carrera}
                  required
                />
              </div>
              <div>
                <label>Materia:</label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  placeholder=""
                  disabled={true}
                  value={this.state.materia.anioAcademicoDescripcion}
                  required
                />
              </div>
              <div>
                {!disable ?
                  <div>
                    <label>Seleccione la Fecha de Examen:</label>
                    <DatePicker className="gsfsafsFSFSf"
                      locale="es"
                      closeOnScroll={true}
                      selected={this.state.fecha}
                      onChange={this.handleFechaExamen}
                      disabled={this.state.loading}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div> :
                  <div>
                    <label> Fecha de Fecha de Examen: </label>
                    <input
                      className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                      name="name" size="30"
                      disabled={true}
                      value={format(new Date(this.state.fecha), 'dd/MM/yyyy')}
                    />
                  </div>}
              </div>
              <div>
                <label> Nota: </label>
                {disable ?
                  <div className="dropdownDictado">
                    <label> {this.handleNota(this.state.observaciones)}</label>
                  </div> :
                  <div className="dropdownDictado">
                    <Select
                      value={this.state.valueDictado}
                      options={options}
                      onChange={this.handleDictadoChangeInput}
                    />
                  </div>
                }
              </div>
              <div>
                <label>Resultado:</label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  placeholder=""
                  disabled={true}
                  value={this.state.resultado}
                  required
                />
              </div>
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-hex"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-hex" />}
      </div>
    );
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/hex',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          HistoriaAcademicaExamenCod: parseInt(this.state.id),
          HistoriaAcademicaResultado: this.state.resultado,
          HistoriaAcademicaExamenNota: this.state.observaciones,
          HistoriaAcademicaFechaExamen: this.state.fecha
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data === "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data === "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
