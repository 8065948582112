import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';
import { LookupPeriodoExamen } from '../PeriodoExamen/LookupPeriodoExamen';
import { LookupMaterias } from '../MateriaCarrera/LookupMaterias';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";


registerLocale('es', es)

export class AddMesaExamen extends Component {
  static displayName = AddMesaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      periodo: { codigo: 0, descripcion: "", fechaInicioPeriodo: new Date(), fechafinPeriodo: new Date() },
      materia: { materiaCodigo: "", materiaNombre: "" },
      showLookupMaterias: false,
      showLookupPeriodoCursada: false,
      fechaExamen: new Date(),
      fechaInicioInscripcion: new Date(),
      fechaFinInscripcion: new Date(),
      botonHabilitado: true
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeletePeriodoCursada = this.handleDeletePeriodoCursada.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleAnioChangeInput = this.handleAnioChangeInput.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleSelectPeriodoCursadaItem = this.handleSelectPeriodoCursadaItem.bind(this);
    this.handleShowPeriodoCursadaLookup = this.handleShowPeriodoCursadaLookup.bind(this);
    this.handleHidePeriodoCursadaLookup = this.handleHidePeriodoCursadaLookup.bind(this);
    this.handleFechaExamen = this.handleFechaExamen.bind(this);
    this.handleFechaInicioInscripcion = this.handleFechaInicioInscripcion.bind(this);
    this.handleFechaFinInscripcion = this.handleFechaFinInscripcion.bind(this);
  }

  handleConfirmButton = () => {
    var okToSave = this.isOkToSave();
    var fechasOk = this.datesOK();
    var fechasEnPeriodo = true;

    if (okToSave && fechasOk && fechasEnPeriodo) {
      this.saveStudent();
    } else {
      var errorMessage = "";

      if (!okToSave) {
        errorMessage = "Todos los campos son obligatorios";
      } else
        if (!fechasOk) {
          errorMessage = "Compruebe las fechas ingresadas, las fechas de inscripción deben ser menor a la fecha del examen";
        }
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    }
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupMaterias: false,
      materia: {
        materiaCodigo: carreraProperties[0],
        materiaNombre: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[3].trim()
      }
    });
  }

  handleSelectPeriodoCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupPeriodoCursada: false,
      periodo: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[3].trim(),
        fechaInicioPeriodo: carreraProperties[4],
        fechaFinPeriodo: carreraProperties[5]
      }
    });
  }

  async componentDidMount() {
    await constantClass.canAccess('WWMesaExamen');
  }

  rendleTagsMateria() {
    var tagData = this.state.tagDataMateria.slice(1)
    return (
      <div>
        {tagData.length > 0 ?
          tagData.map((tag) => (
            <li key={tag.id} className="tag">
              <span className="tag-title">{tag.text}</span>
              <span
                className="tag-close-icon"
                onClick={() => this.handleDelete(tag.id)}
              >
                x
              </span>
            </li>
          ))
          : ""}
      </div>
    );
  }

  handleFechaExamen = (date) => {
    this.setState({ fechaExamen: date });
  }

  handleFechaInicioInscripcion = (date) => {
    this.setState({ fechaInicioInscripcion: date });
  }

  handleFechaFinInscripcion = (date) => {
    this.setState({ fechaFinInscripcion: date });
  }

  handleSelect = e => {
    this.setState({ practica: e.value, value: e });
  }

  handleHideLookup = () => {
    this.setState({ showLookupMaterias: false });
  }

  handleHidePeriodoCursadaLookup = () => {
    this.setState({ showLookupPeriodoCursada: false });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupMaterias: true });
  }

  handleShowPeriodoCursadaLookup = () => {
    this.setState({ showLookupPeriodoCursada: true });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleAnioChangeInput = e => {
    this.setState({ anioDictado: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado: e });
  }

  handleCodeChange = e => {
    this.setState({ code: e.target.value });
  }

  handleDelete = (i) => {
    this.setState({ tagDataMateria: this.state.tagDataMateria.filter(tag => tag.id !== i) });
  }

  handleDeletePeriodoCursada = (i) => {
    this.setState({ tagDataPeriodoCursada: this.state.tagDataPeriodoCursada.filter(tag => tag.id !== i) });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nueva Mesa de Examen</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la materia</Button>
                : <label> Seleccione la materia </label>}
              {this.state.showLookupMaterias ? <LookupMaterias selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.materia.materiaNombre}
              />
            </div>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowPeriodoCursadaLookup}> Seleccione el período de Examen</Button>
                : <label> Seleccione el período de Examen </label>}
              {this.state.showLookupPeriodoCursada ? <LookupPeriodoExamen selectItem={this.handleSelectPeriodoCursadaItem} hideLookup={this.handleHidePeriodoCursadaLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.periodo.descripcion}
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Examen: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.fechaExamen}
                onChange={this.handleFechaExamen}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Inicio de Inscripción: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.fechaInicioInscripcion}
                onChange={this.handleFechaInicioInscripcion}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Fin de Inscripción: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.fechaFinInscripcion}
                onChange={this.handleFechaFinInscripcion}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()}> Confirmar </Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-mex"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-mex" />
        }
      </div>
    );
  }

  isOkToSave() {
    return (this.state.materia.materiaCodigo.length > 0 && this.state.periodo.descripcion.length > 0);
  }

  datesInPeriodo() {
    return (
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.fechaExamen && new Date(this.state.periodo.fechaFinPeriodo) >= this.state.fechaExamen) &&
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.fechaInicioInscripcion && new Date(this.state.periodo.fechaFinPeriodo) >= this.state.fechaInicioInscripcion) &&
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.fechaFinInscripcion && new Date(this.state.periodo.fechaFinPeriodo) >= this.state.fechaFinInscripcion)
    );
  }

  datesOK() {
    return (this.state.fechaInicioInscripcion <= this.state.fechaFinInscripcion && this.state.fechaFinInscripcion < this.state.fechaExamen)
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true, botonHabilitado: false });
    const response = await fetch('api/mex',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          MesaExamenFechaExamen: this.state.fechaExamen,
          MesaExamenFechaInicioInscripci: this.state.fechaInicioInscripcion,
          MesaExamenFechaFinInscripcion: this.state.fechaFinInscripcion,
          PeridoExamenCod: this.state.periodo.codigo,
          MateriaCarreraCod: this.state.materia.materiaCodigo
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage, botonHabilitado: true });
  }
}
