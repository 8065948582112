import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

export class AddAlumnos extends Component {
  static displayName = AddAlumnos.name;

  constructor(props) {
    super(props);
    this.state = { loading: false, dni: "", name: "", showErrorMessage: false, success: false, errorMessage:"" };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveStudent();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Los campos Documento y Nombre son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWAlumnos');
  }

  handleDNIChangeInput = e => {
    this.setState({ dni: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Alumno</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos...": ""}</span>
            <div className="pa2">
              <label> Documento de Identidad: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese DNI"
                onChange={this.handleDNIChangeInput}
                disabled={this.state.loading}
                required
              />
            </div>
            <div className="pa2">
              <label> Nombre Completo: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre Completo"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading}
                required
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-alumnos"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-alumnos" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.dni.length > 0 && this.state.name.length > 0);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Students/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({ AlumnoNombre: this.state.name, AlumnoDni: this.state.dni })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage:errorMessage });
  }
}
