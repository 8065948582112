import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Pagination.css';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { format } from "date-fns";

export class MDMesaExamen extends Component { /*MD= Manejar Datos*/
  static displayName = MDMesaExamen.name;

  constructor(props) {
    super(props);
    this.state = { loading: true, searchData: [], searchInput: "", perPage: 10, paginationData: [], offset: 0, showErrorMessage: false, errorMessage: "" };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.getPeriodoCursada = this.getPeriodoCursada.bind(this);
    this.handleGenerarActa = this.handleGenerarActa.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWMesaExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.materias.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "1") {
      return "Primer Cuatrimestre";
    } else {
      if (periodoCursadaDescripcion == "2") {
        return "Segundo Cuatrimestre";
      }
      else {
        return "Anual";
      }
    }
  }

  handleGenerarActa = (id) => {
    if (!window.confirm("¿Desea generar la/s Acta/s Examen?"))
      return;
    else {
      this.generarActa(id);
    }
  }

  handleChange = e => {
    if (e.target.value !== "") {
      this.setState({ searchInput: e.target.value, loading: true });
      const data = this.state.materias.filter(
        materia => {
          return (
            materia
              .materiaCarreraNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            materia
              .carreraNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            materia
              .periodoExamenDescripcion
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            materia
              .periodoExamenLlamado
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            materia
              .anioAcademicoDescripcion
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        }
      );
      var paginationData = data.slice(0, 0 + this.state.perPage);
      this.setState({ searchData: data, loading: false, paginationData: paginationData, pageCount: Math.ceil(data.length / this.state.perPage) });
    } else {
      var paginationData = this.state.materias.slice(0, 0 + this.state.perPage);
      this.setState({ paginationData: paginationData, pageCount: Math.ceil(this.state.materias.length / this.state.perPage), searchInput: "" });
    }
  }

  renderalumnosTable(cursadas) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Materia</th>
            <th>Carrera</th>
            <th>Período de Examen</th>
            <th>Llamado</th>
            <th>Año Académico</th>
            <th>Fecha de Examen</th>
            <th>Fecha de Inicio de Inscripción</th>
            <th>Fecha de Fin de Inscripción</th>
            <th colSpan="4">Acción</th>
          </tr>
        </thead>
        <tbody>
          {cursadas.map(cursada =>
            <tr key={cursada.mesaExamenCod}>
              <td>{cursada.materiaCarreraNombre}</td>
              <td>{cursada.carreraNombre}</td>
              <td>{cursada.periodoExamenDescripcion}</td>
              <td>{cursada.periodoExamenLlamado}</td>
              <td>{cursada.anioAcademicoDescripcion}</td>
              <td>{format(new Date(cursada.mesaExamenFechaExamen), 'dd/MM/yyyy')}</td>
              <td>{format(new Date(cursada.mesaExamenFechaInicioInscripci), 'dd/MM/yyyy')}</td>
              <td>{format(new Date(cursada.mesaExamenFechaFinInscripcion), 'dd/MM/yyyy')}</td>
              <td>
                <ButtonGroup>
                  <Button className="editButton" size="sm" color="primary" tag={Link} to={"/update-mex/" + cursada.mesaExamenCod +"/0"} >Modificar</Button>
                  <Button className="editButton" size="sm" color="danger" onClick={() => this.handleRemoveItem(cursada.mesaExamenCod)}>Borrar</Button>
                  <Button className="editButton" size="sm" tag={Link} to={"/update-mex/" + cursada.mesaExamenCod + "/1"} >Detalles</Button>
                  {(new Date(cursada.mesaExamenFechaFinInscripcion).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && cursada.generarActa) && <Button className="editButton" size="sm" color="warning" onClick={() => this.handleGenerarActa(cursada.mesaExamenCod)}>Acta</Button>}
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }


  handleItemSelect = id =>
  {
    console.log(id);
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.paginationData);

    return (
      <div>
        <h1>Manejar Datos de Mesa de Examenes</h1>
        {this.state.showErrorMessage && !this.state.loading ?
          <div>
            <span className="labelError"> {"Ocurrió un error al intentar ejecutar la operación." + this.state.errorMessage}</span>
          </div> :
          <div>
            <p>
              <Link to="/add-mex">Nueva Mesa de Examen</Link>
            </p>
            <p>
              <Link to="/cme">Cargar Mesas de Examen desde archivo </Link>
            </p>
            <div className="pa2">
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                type="search"
                placeholder="Ingrese el período de examen o la materia"
                onChange={this.handleChange}
              />
            </div>
            {contents}
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>}

      </div>
    );
  }

  async populateData() { 
    const response = await fetch('api/mex/Index',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      data.returnModel.sort((a, b) => new Date(b.mesaExamenFechaExamen) - new Date(a.mesaExamenFechaExamen));
      const paginationData = data.returnModel.slice(0, this.state.perPage);
      this.setState({ materias: data.returnModel, searchData: data.returnModel, loading: false, pageCount: Math.ceil(data.returnModel.length / this.state.perPage), paginationData: paginationData });
    }
  }

  async deleteItem(id) {
    this.setState({ loading: true });
    const response = await fetch('api/mex/delete/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.populateData();
    }
  }

  async generarActa(id) {
    this.setState({ loading: true });
    const response = await fetch('api/aex/generate/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      window.alert("Actas Generadas exitosamente, si las desea imprimir vaya al menú de Actas de Examenes");
      this.populateData();
    }
  }
}
