import es from 'date-fns/locale/es';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { LookupAlumnoCarrera } from '../AlumnoCarrera/LookupAlumnoCarrera';
import '../Buttons.css';
import { LookupMesaExamen } from '../MesaExamen/LookupMesaExamen';
import * as constantClass from '../fileWithConstants';
import Select from 'react-select';

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Libre', label: 'Libre' },
  { value: 'Equivalencia', label: 'Equivalencia' },
];

registerLocale('es', es)

export class AddInscripcionExamen extends Component {
  static displayName = AddInscripcionExamen.name;

  

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      showCursada: false,
      tagDataAlumno: [{ id: "", text: "" }],
      tagDataCursada: [{ id: "", text: "" }],
      alumno: { codigo: "", descripcion: "" },
      cursada: { codigo: "", descripcion: "" },
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate : new Date(),
      carreraCursada: -1,
      materiaCodigo: "",
      valueDictado: { value: '', label: '' },
      tagData: [{ id: "", text: "", materiaCarreraCod3:""}],
      materiaCarreraCod3: "",
      ignorarCorrelatividad: false,
      ignorarRegularidad: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleShowCursadaLookup = this.handleShowCursadaLookup.bind(this);
    this.handleSelectCursadaItem = this.handleSelectCursadaItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHideLookup1 = this.handleHideLookup1.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleCheckBoxCorrelatividad = this.handleCheckBoxCorrelatividad.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleConfirmButton = () => {
    var isOkToSave = this.isOkToSave(); 
  
    if (!isOkToSave) {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    } else
    {
      this.saveStudent();
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWInscripcionExamen');
  }

  handleDelete = (i) => {
    this.setState({ tagData: this.state.tagData.filter(tag => tag.id !== i) });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado: e });
  }

  handleChange = (e) => {
    this.setState({ llamado: e.value });
  }

  handleDelete = (i) => {
    this.setState({ tagDataAlumno: this.state.tagDataAlumno.filter(tag => tag.id !== i), carreraCursada: -1});
  }

  handleDeleteCursada = (i) => {
    this.setState({ tagDataCursada: this.state.tagDataCursada.filter(tag => tag.id !== i) });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleShowCursadaLookup = () => {
    this.setState({ showCursada: true });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }
  handleHideLookup1 = () => {
    this.setState({ showCursada: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupAnios: false,
      carreraCursada: Number(carreraProperties[3]),
      alumno: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1] + " - " + carreraProperties[2]
      }
    });
  }

  handleSelectCursadaItem = (e) =>
  {
    const carreraProperties = e.split('%');
    const tag = {
      id: carreraProperties[0],
      text: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[4].trim() + " - " + carreraProperties[5].trim() + " - " + carreraProperties[3].trim(),
      materiaCarreraCod3: carreraProperties[6]
    }
    const tagData = this.state.tagData;
    tagData.push(tag);
    this.setState({
      showCursada: false,
      tagData: tagData 
    });
  }

  handleCheckBoxCorrelatividad = (e) => {
    this.setState({ ignorarCorrelatividad: e.target.checked });
  }

  handleCheckBoxRegularidad = (e) => {
    this.setState({ ignorarRegularidad: e.target.checked });
  }

  rendleTags() {
    var tagData = this.state.tagData.slice(1);
    return (
      <div>
        {tagData.length > 0 ?
          tagData.map((tag) => (
            <li key={tag.id} className="tag">
              <span className="tag-title">{tag.text}</span>
              <span
                className="tag-close-icon"
                onClick={() => this.handleDelete(tag.id)}
              >
                x
              </span>
            </li>
          ))
          : ""}
      </div>
    );
  }

  render() {
    var content = this.rendleTags();
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Inscripción a Examen</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>            
            <div>
              
              {!this.state.loading ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Alumno</Button>
                : <label> Datos del Alumno </label>}
              {this.state.showLookupAnios ? <LookupAlumnoCarrera selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.descripcion}
              />           
            </div>
            <div>
              {!this.state.loading && this.state.carreraCursada != -1 ? <Button color="link" onClick={this.handleShowCursadaLookup}> Seleccione la Mesa de Examen</Button>
                : <label> Seleccione el Alumno antes de seleccionar la materia </label>}
              {this.state.showCursada ? <LookupMesaExamen carreraCod={this.state.carreraCursada} selectItem={this.handleSelectCursadaItem} hideLookup={this.handleHideLookup1} /> : ""}
              <div className="tag-input">
                <ul className="tags">
                  {content}
                </ul>
              </div>
            </div>
            <div>
              <label> Condición de Inscripción: </label>
              <div className="dropdownDictado">
                <Select
                  value={this.state.valueDictado}
                  options={optionsCuatrimestre}
                  onChange={this.handleDictadoChangeInput}
                />
              </div>
            </div>
            <div>
              <input type="checkbox" onClick={this.handleCheckBoxCorrelatividad} value={this.state.ignorarCorrelatividad} /> Ignorar verificación de correlatividad
            </div>
            <div>
              <input type="checkbox" onClick={this.handleCheckBoxRegularidad} value={this.state.ignorarRegularidad} /> Ignorar verificación de regularidad
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-ie"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
            <Redirect to="/md-ie" />}
      </div>
    );
  }

  isOkToSave() {
    const tags = this.state.tagData.slice(1);
    return (this.state.alumno.descripcion.length > 1 && tags.length > 0 && this.state.valueDictado.value != "");
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    var object = [];
    this.state.tagData.slice(1).map((tag) => {
      object.push({ Mesa: parseInt(tag.id), MateriaCarreraCod3: tag.materiaCarreraCod3 });
    });
    const response = await fetch('api/ie/multiple',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          AlumnoxCarreraCod:this.state.alumno.codigo,
          InscripcionExamenCondicionInsc: this.state.valueDictado.value,
          Mesas: object,
          IgnorarCorrelatividad: this.state.ignorarCorrelatividad,
          IgnorarRegularidad : this.state.ignorarRegularidad
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
