import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import Select from 'react-select';
import '../Tags.css';
import { LookupCarreras } from '../Carreras/LookupCarreras';

const options = [
  { value: '', label: '' },
  { value: 'S', label: 'Si' },
  { value: 'N', label: 'No' }
];

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: '1', label: 'Primer Cuatrimestre' },
  { value: '2', label: 'Segundo Cuatrimestre' },
  { value: 'A', label: 'Anual' },
];

export class AddMateria extends Component {
  static displayName = AddMateria.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      tagData: [{id:"",text:""}],
      alumno: { alumnoDni: "", alumnoCodigo: 0, alumnoNombre: "" },
      carrera: { codigo: "", nombre: "" },
      showLookupStudent: false,
      showLookupCarreras: false,
      practica: "",
      value: { value: '', label: '' },
      valueDictado: { value: '', label: '' },
      name:""
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleAnioChangeInput = this.handleAnioChangeInput.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveStudent();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWMaterias');
  }

  handleSelectCarreraItem = (e) =>
  {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      carrera: {
        codigo: carreraProperties[0],
        nombre: carreraProperties[1]
      }
    });
  }

  handleSelect = e => {
    this.setState({ practica: e.value, value: e });
  }

  handleHideLookup = () =>
  {
    this.setState({showLookupCarreras:false});
  }

  handleShowCarreraLookup = () =>
  {
    this.setState({showLookupCarreras:true});
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleAnioChangeInput = e => {
    this.setState({ anioDictado: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado:e });
  }

  handleCodeChange = e => {
    this.setState({ code: e.target.value });
  }

  handleDelete = (i) => {
    this.setState({tagData:this.state.tagData.filter(tag =>tag.id !== i)});
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nueva Materia</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Código Materia: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese Código"
                onChange={this.handleCodeChange}
                disabled={this.state.loading}
                required
              />
            </div>
            <div className="pa2">
              <label> Nombre Materia: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading }
                required
              />
            </div>
            <div className="pa2">
              <label> Año Dictado: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="10"
                placeholder="Ingrese Año"
                onChange={this.handleAnioChangeInput}
                disabled={this.state.loading}
                required
              />
            </div>
            <div>
              <label> Período de Dictado: </label>
              <div className="dropdownDictado">
                <Select
                  value={this.state.valueDictado}
                  options={optionsCuatrimestre}
                  onChange={this.handleDictadoChangeInput}
                />
              </div>
            </div>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la carrera</Button>
                  : <label> Seleccione la carrera </label>}
              {this.state.showLookupCarreras ? <LookupCarreras selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <div className="pa2">
                <label> Código Materia: </label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  placeholder="Carrera"
                  disabled={true}
                  value={ this.state.carrera.codigo.trim() + " - "+ this.state.carrera.nombre.trim()}
                />
              </div>
            </div>
            <div>
              <label> Materia Práctica: </label>
            <div className="dropdown">
              <Select
                value={this.state.value}
                options={options}
                onChange={this.handleSelect}
              />
              </div>
              </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-mxc"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-mxc" />
        }
      </div>
    );
  }

  isOkToSave() {
    return (this.state.anioDictado.length > 0 && this.state.carrera.nombre.length >= 1 && this.state.name.length>0 && this.state.code.length>0 && this.state.dictado.length>0 && this.state.practica.length>0);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Materia',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          MateriaCarreraCodigo: this.state.code,
          MateriaCarreraNombre: this.state.name,
          MateriaCarreraDictado: this.state.dictado,
          MateriaCarreraAnio: this.state.anioDictado,
          MateriaPractica: this.state.practica,
          CarreraCod : this.state.carrera.codigo
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
