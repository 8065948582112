import es from 'date-fns/locale/es';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

registerLocale('es', es)

export class InscribirAlumnosPrimero extends Component {
  static displayName = InscribirAlumnosPrimero.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      showCursada: false,
      tagDataAlumno: [{ id: "", text: "" }],
      tagDataCursada: [{ id: "", text: "" }],
      alumno: { codigo: "", descripcion: "" },
      cursada: { codigo: "", descripcion: "" },
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate: new Date(),
      carreraCursada: -1,
      materiaCodigo: "",
      base64: "",
      selectedFile: null
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleShowCursadaLookup = this.handleShowCursadaLookup.bind(this);
    this.handleSelectCursadaItem = this.handleSelectCursadaItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHideLookup1 = this.handleHideLookup1.bind(this);
  }

  handleConfirmButton = () => {
    var isOkToSave = this.isOkToSave();

    if (!isOkToSave) {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    } else {
      this.saveStudent();
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWAlumnos');
  }

  handleChange = (e) => {
    this.setState({ llamado: e.value });
  }

  handleDelete = (i) => {
    this.setState({ tagDataAlumno: this.state.tagDataAlumno.filter(tag => tag.id !== i), carreraCursada: -1 });
  }

  handleDeleteCursada = (i) => {
    this.setState({ tagDataCursada: this.state.tagDataCursada.filter(tag => tag.id !== i) });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleShowCursadaLookup = () => {
    this.setState({ showCursada: true });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }
  handleHideLookup1 = () => {
    this.setState({ showCursada: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    var reader = new FileReader();
    var file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64 = reader.result.split(',')[1];
      this.setState({ showErrorMessage: false, errorMessage: '', base64: base64});
    };
    reader.onerror = (error) => {
      this.setState({ showErrorMessage: true, errorMessage: 'Error al leer el archivo:' + error });
    };
  }

  handleSelectAnioItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupAnios: false,
      carreraCursada: Number(carreraProperties[3]),
      alumno: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1] + " - " + carreraProperties[2]
      }
    });
  }

  handleSelectCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showCursada: false,
      materiaCodigo: carreraProperties[5],
      cursada: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[4].trim()
      }
    });
  }

  render() {
    return (
      <div>
        <div>
          <h1>Inscribir a Alumnos de primer Año</h1>
          <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
          <span className="label"> {this.state.loading ? "Inscribiendo Alumnos" : ""}</span>
          <span className="label"> {(this.state.success && !this.state.loading) ? "Inscripción de Alumnos exitosa" : ""}</span>
          <div>
            <input
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
              type="file"
              onChange={this.handleLlamadoChangeInput}
            />
          </div>
          <ButtonGroup>
            {!this.state.loading ? (
              <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Inscribir Alumnos</Button>
            ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Inscribir Alumnos</Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    );
  }

  isOkToSave() {
    return (this.state.base64.length > 1);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Students/archivo',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          Archivo: this.state.base64
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage,  base64:"" });
  }
}
