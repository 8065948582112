import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import { Redirect } from 'react-router';
import * as constantClass from '../fileWithConstants';
import { LookupAlumnos } from '../Alumnos/LookupAlumnos';
import { LookupCarreras } from '../Carreras/LookupCarreras';


export class UpdateAlumnoCarrera extends Component {
  static displayName = UpdateAlumnoCarrera.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      alumno: { alumnoDni: "", alumnoCodigo: 0, alumnoNombre: "" },
      carrera: { carreraCod: 0, carreraNombre: "" },
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleHideStudentLookup = this.handleHideStudentLookup.bind(this);
    this.handleHideCarreraLookup = this.handleHideCarreraLookup.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWAlumnosxCarrera');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      if (this.isOkToSave()) {
        this.saveStudent();
      } else {
        this.setState({ showErrorMessage: true, errorMessage: "Los campos Alumno y Carrera son obligatorios" });
      }
    }
  }

  handleDNIChangeInput = e => {
    this.setState({ dni: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleShowStudentLookup = () => {
    this.setState({ showLookupStudent: true });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupCarreras: true });
  }

  handleSelectStudentItem = (e) => {
    const studentProperties = e.split('%');
    this.setState(
      {
        alumno: {
          alumnoDni: studentProperties[0],
          alumnoNombre: studentProperties[1],
          alumnoCodigo: parseInt(studentProperties[2])
        },
        showLookupStudent: false
      });
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      carrera: {
        carreraCod: parseInt(carreraProperties[0]),
        carreraNombre: carreraProperties[1]
      }
    });
  }

  handleHideStudentLookup = () => {
    this.setState({ showLookupStudent: false });
  }

  handleHideCarreraLookup = () => {
    this.setState({ showLookupCarreras: false });
  }


  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Alumno por Carrera" : "Modificar Alumno por Carrera";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              {!disable ? <Button color="link" onClick={this.handleShowStudentLookup}> Seleccione el Alumno</Button> :
                <label> Datos del Alumno </label>}
              {this.state.showLookupStudent ? <LookupAlumnos selectStudent={this.handleSelectStudentItem} hideLookup={this.handleHideStudentLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.alumnoDni}
              />
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.alumnoNombre}
              />
            </div>
            <div>
              {!disable ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la carrera</Button> :
                <label> Datos de la carrera </label>}
              {this.state.showLookupCarreras ? <LookupCarreras selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideCarreraLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.carrera.carreraNombre}
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-axc"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-axc" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.alumno.alumnoDni.length > 0 && this.state.carrera.carreraNombre.length > 0);
  }

  async populateData() {
    const response = await fetch('api/axc/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    this.setState(
      {
        errorMessage: errorMessage,
        alumno: {
          alumnoDni: data.returnModel.alumnoDni,
          alumnoNombre: data.returnModel.alumnoNombre,
          alumnoCodigo: data.returnModel.alumnoCodigo,
        },
        carrera: {
          carreraNombre: data.returnModel.carreraNombre,
          carreraCod: data.returnModel.carreraCod
        }
      })
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/axc/update',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': constantClass.AuthorizationHeader,
        },
        body: JSON.stringify({
          alumnoCodigo: this.state.alumno.alumnoCodigo,
          carreraCod: this.state.carrera.carreraCod,
          alumnoxCarreraCod: parseInt(this.state.id)
        })
      });
    var data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
