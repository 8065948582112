import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupMesaExamen } from '../MesaExamen/LookupMesaExamen';
import { LookupAlumnoCarrera } from '../AlumnoCarrera/LookupAlumnoCarrera';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

registerLocale('es', es)

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Libre', label: 'Libre' },
  { value: 'Equivalencia', label: 'Equivalencia' },
];


export class UpdateInscripcionExamen extends Component {
  static displayName = UpdateInscripcionExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      showLookupCursada: false,
      tagData: [{ id: "", text: "" }],
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate: new Date(),
      llamado: "",
      valueDictado: { value: '', label: '' },
      cursada: { cursadaCodigo: 0, cursadaMateriaCodigo: "", cursadaMateriaNombre: "", cursadaPeriodo: "" },
      alumno: { alumnocarreraCod: 0, alumnoNombre: "", alumnoCarrera: 0, alumnoCarreraNombre: "" },
      id: this.props.match.params.id,
      details: this.props.match.params.details
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEndInscripcionDate = this.handleEndInscripcionDate.bind(this);
    this.handleStartInscriptionDate = this.handleStartInscriptionDate.bind(this);
    this.getPeriodoCursada = this.getPeriodoCursada.bind(this);
    this.getEstadoInscripcion = this.getEstadoInscripcion.bind(this);
    this.handleShowCursadaLookup = this.handleShowCursadaLookup.bind(this);
    this.handleSelectCursadaItem = this.handleSelectCursadaItem.bind(this);
    this.handleHideLookup1 = this.handleHideLookup1.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWInscripcionExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      var errorMessage = "";
      var isOkToSave = this.isOkToSave();

      if (!isOkToSave) {
        errorMessage = "Todos los campos son obligatorios";
        this.setState({ showErrorMessage: true, errorMessage: errorMessage });
      } else {
        this.saveStudent();
      }
    }
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado: e });
  }

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "1") {
      return "Primer Cuatrimestre";
    } else {
      if (periodoCursadaDescripcion == "2") {
        return "Segundo Cuatrimestre";
      }
      else {
        return "Anual";
      }
    }
  }

  async populateData() {
    const response = await fetch('api/ie/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    var defaultValuePractica = optionsCuatrimestre.filter(x => x.value.toString().trim() === data.returnModel.inscripcionExamenCondicionInsc.trim());
    this.setState(
      {
        errorMessage: errorMessage,
        dictado: data.returnModel.inscripcionExamenCondicionInsc.trim(),
        cursada: {
          cursadaCodigo: data.returnModel.mesaExamenCod,
          cursadaMateriaCodigo: data.returnModel.mesaExamenCodNavigation.materiaCarreraCod,
          cursadaMateriaNombre: data.returnModel.mesaExamenCodNavigation.materiaCarreraNombre,
          cursadaPeriodo: data.returnModel.mesaExamenCodNavigation.periodoExamenDescripcion.trim() + " - " + data.returnModel.mesaExamenCodNavigation.periodoExamenLlamado.trim() + " - " + data.returnModel.mesaExamenCodNavigation.anioAcademicoDescripcion.trim()
        },
        alumno: {
          alumnocarreraCod: data.returnModel.alumnoxCarreraCodNavigation.alumnoxCarreraCod,
          alumnoNombre: data.returnModel.alumnoxCarreraCodNavigation.alumnoNombre,
          alumnoCarrera: data.returnModel.alumnoxCarreraCodNavigation.carreraCod,
          alumnoCarreraNombre: data.returnModel.alumnoxCarreraCodNavigation.carreraNombre
        },
        descripcion: data.returnModel.inscripcionExamenCondicionInsc,
        valueDictado: defaultValuePractica
      });
  }

  handleEndInscripcionDate = (date) => {
    this.setState({ endInscriptionDate: date });
  }

  handleStartInscriptionDate = (date) => {
    this.setState({ startInscriptionDate: date });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleShowCursadaLookup = () => {
    this.setState({ showLookupCursada: true });
  }

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }
  handleHideLookup1 = () => {
    this.setState({ showLookupCursada: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const anioProperties = e.split('%');
    this.setState({
      alumno: {
        alumnocarreraCod: anioProperties[0],
        alumnoNombre: anioProperties[1],
        alumnoCarrera: anioProperties[3],
        alumnoCarreraNombre: anioProperties[2]
      },
      showLookupAnios: false
    });
  }

  handleSelectCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCursada: false,
      materiaCodigo: carreraProperties[6],
      cursada: {
        cursadaCodigo: carreraProperties[0],
        cursadaMateriaCodigo: carreraProperties[6],
        cursadaMateriaNombre: carreraProperties[1].trim(),
        cursadaPeriodo: carreraProperties[2].trim() + " - " + carreraProperties[4].trim() + " - " + carreraProperties[5].trim() + " - " + carreraProperties[3].trim()
      }
    });
  }

  handleChange = (e) => {
    this.setState({ descripcion: e.value });
  }

  getEstadoInscripcion(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "A") {
      return "Activa";
    } else {
      if (periodoCursadaDescripcion == "P") {
        return "Pendiente";
      }
      else if (periodoCursadaDescripcion == "G") {
        return "Acta Generada";
      }
      else {
        return "Lista";
      }
    }
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Inscripción a Examen" : "Modificar datos de Inscripción a Examen";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <div>
                {!disable ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Alumno</Button> :
                  <label> Datos del Alumno </label>}
                {this.state.showLookupAnios ? <LookupAlumnoCarrera selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  disabled={true}
                  value={this.state.alumno.alumnoNombre + " - " + this.state.alumno.alumnoCarreraNombre}
                />
              </div>
              <div>
                {!disable ? <Button color="link" onClick={this.handleShowCursadaLookup}> Seleccione la Materia</Button> :
                  <label> Datos de la Materia </label>}
                {this.state.showLookupCursada ? <LookupMesaExamen carreraCod={this.state.alumno.alumnoCarrera} selectItem={this.handleSelectCursadaItem} hideLookup={this.handleHideLookup1} /> : ""}
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="30"
                  disabled={true}
                  value={this.state.cursada.cursadaMateriaNombre.trim() + " - " + this.state.alumno.alumnoCarreraNombre.trim() + " - " + this.state.cursada.cursadaPeriodo}
                />
              </div>
              <div>
                {!disable ?
                  <div>
                    <label> Condición de Inscripción: </label>
                    <div className="dropdownDictado">
                      <Select
                        value={this.state.valueDictado}
                        options={optionsCuatrimestre}
                        onChange={this.handleDictadoChangeInput}
                      />
                    </div>
                  </div>
                  :
                  <div>
                    <label> Condición de Inscripción: </label>
                    <input
                      className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                      name="name" size="10"
                      onChange={this.handleDescripcionChangeInput}
                      disabled={this.state.loading || disable}
                      value={this.state.dictado}
                      required
                    />
                  </div>}
              </div>
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-ie"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-ie" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.dictado.length > 0);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/ie/update',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          AlumnoxCarreraCod: this.state.alumno.alumnocarreraCod,
          MesaExamenCod: this.state.cursada.cursadaCodigo,
          InscripcionExamenCod: this.state.id,
          InscripcionExamenCondicionInsc: this.state.dictado,
          MateriaCarreraCod3: this.state.cursada.cursadaMateriaCodigo,
          IgnorarCorrelatividad: true,
          IgnorarRegularidad: true
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
