import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Redirect } from 'react-router';
import '../Buttons.css';
import { Link } from 'react-router-dom';
import * as constantClass from '../fileWithConstants';
import '../Pagination.css';
import Select from 'react-select';
import { format } from "date-fns";

const options = [
  { value: '0', label: 'Ausente' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
];

export class CargarActaExamen extends Component { /*MD= Manejar Datos*/
  static displayName = CargarActaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchData: [],
      searchInput: "",
      perPage: 10,
      paginationData: [],
      offset: 0,
      showErrorMessage: false,
      errorMessage: "",
      alumnos: [],
      materia: "",
      fechaExamen: new Date().toString(),
      tipoActa: "",
      id: this.props.match.params.id,
      resultados: [],
      folio: "",
      libro: "",
      encabezadoOk: false
    };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleNotaOral = this.handleNotaOral.bind(this);
    this.handleNotaEscrito = this.handleNotaEscrito.bind(this);
    this.handleNotaPromedio = this.handleNotaPromedio.bind(this);
    this.handleOnChangeFolio = this.handleOnChangeFolio.bind(this);
    this.handleOnChangeLibro = this.handleOnChangeLibro.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  handleConfirmButton = () => {
    if (this.state.folio === 0 || this.state.libro.trim() === '0') {
      this.setState({ errorMessage: "Ingrese Libro y/o Folio", showErrorMessage: true })
    } else {
      this.setState({ showErrorMessage: false })
      this.postNotas();
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWActasExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.alumnos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  allRowsWereSaved() {
    let todoOk = true;

    this.state.alumnos.forEach(y => {
      const index = this.state.resultados.findIndex(x => x.id === y.detalleActaCursadaCod);

      if (index < 0) {
        todoOk = false;
        return;
      }
    });

    return todoOk;
  }

  allDataPopulated() {
    let todoOk = true;

    this.state.resultados.forEach(x => {
      if (x.resultado === '') {
        todoOk = false;
        return;
      }
    })
    return todoOk;
  }

  handleOnChangeFolio = (e) => {
    this.setState({ folio: e.target.value });
  }

  handleOnChangeLibro = (e) => {
    this.setState({ libro: e.target.value });
  }

  handleNotaOral = (e, id) => {
    const index = this.state.resultados.findIndex(x => x.id === e);

    if (index >= 0) {
      const element = this.state.resultados[index];
      element.oral = id.value;
      this.state.resultados.splice(index, 1, element);
      this.setState({ valueOral: id });
    }
  }

  handleNotaEscrito = (e, id) => {
    const index = this.state.resultados.findIndex(x => x.id === e);

    if (index >= 0) {
      const element = this.state.resultados[index];
      element.escrito = id.value;
      this.state.resultados.splice(index, 1, element);
      this.setState({ valueEscrito: id });
    }
  }

  handleNotaPromedio = (e, id) => {
    const index = this.state.resultados.findIndex(x => x.id === e);

    if (index >= 0) {
      const element = this.state.resultados[index];
      element.nota = id.value;
      this.state.resultados.splice(index, 1, element);
      this.setState({ valueNota: id });
    }
  }

  renderalumnosTable(alumnos) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>DNI</th>
            <th>Nombre y Apellido</th>
            <th>Nota Examen Oral</th>
            <th>Nota Examen Escrito</th>
            <th>Nota Examen Promedio</th>
          </tr>
        </thead>
        <tbody>
          {alumnos.map(alumno =>
            <tr key={alumno.detalleActaCod}>
              <td>{alumno.alumnoDni}</td>
              <td>{alumno.alumnoNombre}</td>
              <td>{< Select
                options={options}
                disabled={true}
                placeholder="Seleccione un resultado"
                value={options.filter(x => x.value == this.state.resultados.filter(y => y.id === alumno.detalleActaCod)[0].oral)}
                onChange={(e) => this.handleNotaOral(alumno.detalleActaCod, e)}
              />}</td>
              <td>{< Select
                options={options}
                disabled={true}
                value={options.filter(x => x.value == this.state.resultados.filter(y => y.id === alumno.detalleActaCod)[0].escrito)}
                onChange={(e) => this.handleNotaEscrito(alumno.detalleActaCod, e)}
              />}</td>
              <td>{< Select
                options={options}
                disabled={true}
                value={options.filter(x => x.value == this.state.resultados.filter(y => y.id === alumno.detalleActaCod)[0].nota)}
                onChange={(e) => this.handleNotaPromedio(alumno.detalleActaCod, e)}
              />}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.searchData);

    return (
      <div>
        {(!this.state.success && !this.state.encabezadoOk) ?
          <div>
            <h1>Cargar Resultados de Acta de Examen</h1>
            <div>
              {this.state.showErrorMessage &&
                <div>
                  <span className="labelError"> {this.state.errorMessage}</span>
                </div>
              }
            </div>
            <div>
              <h4>Materia: {this.state.materia}</h4>
              <h6>Tipo Acta: {this.state.tipoActa == 'R' ? "Regular" : this.state.tipoActa == 'L' ? "Libre" : this.state.tipoActa == 'E' ? "Equivalencia" : ""}</h6>
              <h6>Fecha Examen: {format(new Date(this.state.fechaExamen), 'dd/MM/yyyy')}</h6>
              <div className="gsdgjasf">
                <ButtonGroup>
                  {!this.state.loading ? (
                    <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
                  ) : (
                    <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
                  )}
                  {!this.state.loading ? (
                    <Button size="sm" tag={Link} to={"/md-aex"} >Cancelar</Button>
                  ) : (
                    <Button size="sm">Cancelar</Button>
                  )}
                </ButtonGroup>
              </div>
              <div className="side-by-side">
                <label>Libro:</label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 actaInput"
                  name="name" size="30"
                  onChange={this.handleOnChangeLibro}
                />
                <label className="labelInput">Folio:</label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 actaInput"
                  name="name" size="30"
                  onChange={this.handleOnChangeFolio}
                />
              </div>
              <div>
                {contents}
              </div>
            </div>
          </div>
          : <Redirect to="/md-aex" />}
      </div >
    );
  }

  async populateData() {
    const response = await fetch('api/aex/detalle/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      const resultados = [];
      data.returnModel.forEach(x => {
        resultados.push({ id: x.detalleActaCod, oral: 0, escrito: 0, nota: 0 });
      });
      var defaultValueAnioDictado = options.filter(x => x.value == '0');
      this.setState({
        alumnos: data.returnModel,
        folio: data.returnModel[0].actaExamenFolio,
        libro: data.returnModel[0].actaExamenLibro,
        materia: data.returnModel[0].materiaCarreraNombre.trim() + " - " + data.returnModel[0].carreraNombre.trim(),
        fechaExamen: data.returnModel[0].mesaExamenFechaExamen,
        tipoActa: data.returnModel[0].actaExamenTipo,
        searchData: data.returnModel,
        loading: false,
        resultados: resultados,
        valueOral: defaultValueAnioDictado,
        valueEscrito: defaultValueAnioDictado,
        valueNota: defaultValueAnioDictado
      });
    }
  }

  async postNotas() {
    this.setState({ loading: true });
    const objects = [];
    this.state.resultados.map((tag) => {
      objects.push({ DetalleActaCod: tag.id, DetalleActaNota: tag.nota, DetalleActaOral: tag.oral, DetalleActaEscrito: tag.escrito });
    });
    const response = await fetch('api/aex',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          ActaExamenFolio: this.state.folio,
          ActaExamenLibro: this.state.libro,
          ActaExamenNacta: this.state.id,
          Resultados: objects
        })
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      window.alert("Acta cargada exitosamente");
      this.setState({ success: true });
    }
  }
}
