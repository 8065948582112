import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Button, ButtonGroup } from 'reactstrap';
import * as constantClass from '../fileWithConstants';
import '../App.css';

export class LookupAlumnoCarrera extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.state = {
      show: true,
      loading: true,
      searchData: [],
      alumnos: [],
      searchInput: "",
      perPage: 5,
      paginationData: [],
      offset: 0,
      showErrorMessage: false,
      errorMessage: ""
    }
  }

  handleModal() {
    this.setState({ show: !this.state.show })
    this.props.hideLookup();
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWAlumnosxCarrera');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.alumnos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleItemSelect = e => {
    this.props.selectItem(e);
    this.setState({ show: false });
  }

  renderalumnosTable(periodos) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>DNI</th>
            <th>Alumno</th>
            <th>Carrera</th>
            <th colSpan="1"></th>
          </tr>
        </thead>
        <tbody>
          {periodos.map(alumno  =>
            <tr key={alumno.alumnoxCarreraCod}>
              <td>{alumno.alumnoNombre}</td>
              <td>{alumno.alumnoDni}</td>
              <td>{alumno.carreraNombre}</td>
              <td>
                <ButtonGroup>
                  <Button className="editButton" size="sm" color="primary" onClick={() => this.handleItemSelect(alumno.alumnoxCarreraCod + "%"
                    + alumno.alumnoNombre + "%" + alumno.carreraNombre + "%" + alumno.carreraCod)} >Seleccionar</Button>
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  handleChange = e => {
    if (e.target.value !== "") {
      this.setState({ searchInput: e.target.value, loading: true });
      const data = this.state.alumnos.filter(
        periodo => {
          return (
            periodo
              .alumnoDni
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            periodo
              .alumnoNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        }
      );
      var paginationData = data.slice(0, 0 + this.state.perPage);
      this.setState({ searchData: data, loading: false, paginationData: paginationData, pageCount: Math.ceil(data.length / this.state.perPage) });
    } else {
      var paginationData = this.state.alumnos.slice(0, 0 + this.state.perPage);
      this.setState({ paginationData: paginationData, pageCount: Math.ceil(this.state.alumnos.length / this.state.perPage), searchInput: "" });
    }
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.paginationData);

    return (
      <div>
        <Modal size="lg" show={this.state.show} onHide={() => this.handleModal()}>
          <Modal.Header closeButton>Seleccione el Alumno</Modal.Header>
          <Modal.Body>
            <div className="pa2">
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                type="search"
                placeholder="Ingrese el Nombre del alumno o el DNI"
                onChange={this.handleChange}
              />
            </div>
            {contents}
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  async populateData() {
    const response = await fetch('api/axc/Index', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      data.returnModel.sort((a, b) => b.alumnoxCarreraCod - a.alumnoxCarreraCod);
      const paginationData = data.returnModel.slice(0, this.state.perPage);
      this.setState({ alumnos: data.returnModel, searchData: data.returnModel, loading: false, pageCount: Math.ceil(data.returnModel.length / this.state.perPage), paginationData: paginationData });
    }
  }
}  