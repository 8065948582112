import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupAnioAcademicos } from '../AnioAcademico/LookupAnioAcademicos';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

registerLocale('es', es)

export class AddPeriodoCursada extends Component {
  static displayName = AddPeriodoCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      tagData: [{ id: "", text: "" }],
      anio: { codigo: "", descripcion: "" },
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate : new Date(),
      llamado: ""
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndInscripcionDate = this.handleEndInscripcionDate.bind(this);
    this.handleStartInscriptionDate = this.handleStartInscriptionDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await constantClass.canAccess('WWPeriodoCursada');
  }

  handleConfirmButton = () => {
    var errorMessage = "";
    var datesOk = this.areDatesOk();
    var inscriptionDatesOk = this.areInscriptionDateOk();
    var isOkToSave = this.isOkToSave();
    var inscriptionDateInsidePeriodDates = this.areInscriptionDateInsidePeriodDates();  
  
    if (!isOkToSave)
    {
      errorMessage = "Todos los campos son obligatorios";
      this.setState({ showErrorMessage: true });
    }

    if (!datesOk)
    {
      if (errorMessage.length > 0) {
        errorMessage = errorMessage + ", la fecha de inicio no puede ser posterior a la fecha de fin";
      } else
      {
        errorMessage = "La fecha de inicio no puede ser posterior a la fecha de fin"
      }
      this.setState({ showErrorMessage: true });
    }

    if (!inscriptionDatesOk)
    {
      if (errorMessage.length > 0) {
        errorMessage = errorMessage + ", la fecha de inicio de inscripción no puede ser posterior a la fecha de fin";
      } else {
        errorMessage = "La fecha de inicio de inscripción no puede ser posterior a la fecha de fin"
      }
      this.setState({ showErrorMessage: true });
    }

    if (!inscriptionDateInsidePeriodDates) {
      if (errorMessage.length > 0) {
        errorMessage = errorMessage + ", las fechas de inscripción deben estar dentro del rango de fechas ingresado para el período de cursada";
      } else {
        errorMessage = "Las fechas de inscripción deben estar dentro del rango de fechas ingresado para el período de cursada"
      }
      this.setState({ showErrorMessage: true });
    }

    if (isOkToSave && datesOk && inscriptionDatesOk && inscriptionDateInsidePeriodDates) {
      this.saveStudent();
    } else {
      this.setState({errorMessage: errorMessage });
    }
  }

  handleChange = (e) => {
    this.setState({ llamado: e.value });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleEndInscripcionDate = (date) => {
    this.setState({ endInscriptionDate: date });
  }

  handleStartInscriptionDate = (date) => {
    this.setState({ startInscriptionDate: date });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      anio: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1]
      }
    });
  }

  render() {
    var options = [
      { value: '', label: '' },
      { value: 'A', label: 'Anual' },
      { value: '1', label: 'Primer Cuatrimestre' },
      { value: '2', label: 'Segundo Cuatrimestre' }
    ];
    var defaultValuePosition = 0;
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Período de Cursada</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>
              <label> Seleccione el Período de Cursada: </label>
              <Select
                options={options}
                defaultValue={options[defaultValuePosition]}
                onChange={this.handleChange}
              />
            </div>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Año Académico</Button>
                : <label> Seleccione el Año Académico </label>}
              {this.state.showLookupAnios ? <LookupAnioAcademicos selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.anio.descripcion}
              />
              <div>
                <label> Seleccione la Fecha de Inicio: </label>
                <DatePicker className="gsfsafsFSFSf"
                  locale="es"
                  closeOnScroll={true}
                  selected={this.state.startDate}
                  onChange={this.handleStartDate}
                  disabled={this.state.loading}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <label> Seleccione la Fecha de Fin: </label>
                <DatePicker className="gsfsafsFSFSf"
                  locale="es"
                  closeOnScroll={true}
                  selected={this.state.endDate}
                  onChange={this.handleEndDate}
                  disabled={this.state.loading}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <label> Seleccione la Fecha de Inicio de Inscripción: </label>
                <DatePicker className="gsfsafsFSFSf"
                  locale="es"
                  closeOnScroll={true}
                  selected={this.state.startInscriptionDate}
                  onChange={this.handleStartInscriptionDate}
                  disabled={this.state.loading}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <label> Seleccione la Fecha de Fin de Inscripción: </label>
                <DatePicker className="gsfsafsFSFSf"
                  locale="es"
                  closeOnScroll={true}
                  selected={this.state.endInscriptionDate}
                  onChange={this.handleEndInscripcionDate}
                  disabled={this.state.loading}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-pcr"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
            <Redirect to="/md-pcr" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.llamado.length > 0 && this.state.anio.descripcion.length > 1 && this.state.startDate != null && this.state.endDate != null && this.state.endInscriptionDate != null && this.state.startInscriptionDate != null);
  }

  areDatesOk() {
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);
  

    return (endDate > startDate);
  }

  areInscriptionDateOk() {
    const startInscripcionDate = new Date(this.state.startInscriptionDate);
    const endInscriptionDate = new Date(this.state.endInscriptionDate);

    return (endInscriptionDate > startInscripcionDate);
  }

  areInscriptionDateInsidePeriodDates() {
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);
    const startInscripcionDate = new Date(this.state.startInscriptionDate);
    const endInscriptionDate = new Date(this.state.endInscriptionDate);

    return (endDate >= startInscripcionDate) && (startInscripcionDate >= startDate) && (endDate >= endInscriptionDate) &&(endInscriptionDate >= startDate);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/pcr',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          PeriodoCursadaFechaInicio: this.state.startDate,
          PeriodoCursadaDescripcion : this.state.llamado,
          PeriodoCursadaFechaFin: this.state.endDate,
          PeriodoCursadaInicioInscripcio: this.state.startInscriptionDate,
          PeriodoCursadaFinInscripcion: this.state.endInscriptionDate,
          AnioAcademicoCod: this.state.anio.codigo
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
