import Cookies from 'js-cookie';

export const AuthorizationHeader = "U2lzdGVtYUdlc3Rpb25BbHVtbm9zX1BJQUdFVF8yMDIy";

export const RedirectToLogin = () => {
  Cookies.set('authToken', false);
  Cookies.set('alumnoCodigos', "");
  window.dispatchEvent(new CustomEvent('authenticated'));
}

export const ReturnUserName = () => {
  return Cookies.get('username');
}

export const canAccess = async (modulo) => {
  const alumnosCodigo = Cookies.get('alumnoCodigos')?.split(',');

  let perfil = 0;
  if (alumnosCodigo)
  {
    if (alumnosCodigo.length == 1 && alumnosCodigo[0] == -1) {
      perfil = 1;
    } else {
      perfil = 3;
    }
  }
  const response = await fetch('api/modulo/canaccess/' + modulo + '/' + perfil,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': AuthorizationHeader,
      }
    });

  const data = await response.json();
  if (!data.returnModel ) {
    RedirectToLogin();
  }
  return true;
}