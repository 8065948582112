import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import { Redirect } from 'react-router';
import * as constantClass from '../fileWithConstants';

export class UpdateAnioAcademico extends Component {
  static displayName = UpdateAnioAcademico.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWAnioAcademico');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      if (this.isOkToSave()) {
        this.saveStudent();
      } else {
        this.setState({ showErrorMessage: true, errorMessage: "El campo Descripción es obligatorio" });
      }
    }
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Año Académico" : "Modificar Año Académico";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{ title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Año Académico: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese Año"
                onChange={this.handleDescripcionChangeInput}
                disabled={this.state.loading||disable}
                value={this.state.descripcion}
                required
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-anios"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-anios" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.descripcion.length > 0);
  }

  async populateData() {
    const response = await fetch('api/anioAcademico/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    this.setState({ descripcion: data.returnModel.anioAcademicoDescripcion,errorMessage:errorMessage })
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/anioAcademico/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({ AnioAcademicoDescripcion: this.state.descripcion, AnioAcademicoCod: parseInt(this.state.id) })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage:errorMessage});
  }
}
