import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Pagination.css';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { format } from "date-fns";

export class MDActaExamen extends Component { /*MD= Manejar Datos*/
  static displayName = MDActaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchData: [],
      searchInput: "",
      perPage: 10,
      paginationData: [],
      offset: 0,
      showErrorMessage: false,
      errorMessage: "",
      alumnos: []
    };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.imprimirActa = this.imprimirActa.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("Va a ser a cerrar el acta, ¿está seguro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  imprimirActa = (id) => {
    this.ImprimirActa(id);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWActasExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.alumnos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleChange = e => {
    if (e.target.value !== "") {
      this.setState({ searchInput: e.target.value, loading: true });
      const data = this.state.alumnos.filter(
        person => {
          return (
            person
              .materiaCarreraNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            person
              .carreraNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            person.actaExamenEstado
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        }
      );
      var paginationData = data.slice(0, 0 + this.state.perPage);
      this.setState({ searchData: data, loading: false, paginationData: paginationData, pageCount: Math.ceil(data.length / this.state.perPage) });
    } else {
      var paginationData = this.state.alumnos.slice(0, 0 + this.state.perPage);
      this.setState({ paginationData: paginationData, pageCount: Math.ceil(this.state.alumnos.length / this.state.perPage), searchInput: "" });
    }
  }

  renderalumnosTable(alumnos) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Materia</th>
            <th>Carrera</th>
            <th>Mesa de Examen</th>
            <th>Fecha de Examen</th>
            <th>Estado Acta</th>
            <th>Tipo Acta</th>
            <th>Libro</th>
            <th>Número de Folio</th>
            <th colSpan="4">Acción</th>
          </tr>
        </thead>
        <tbody>
          {alumnos.map(alumno =>
            <tr key={alumno.actaExamenNacta}>
              <td>{alumno.materiaCarreraNombre}</td>
              <td>{alumno.carreraNombre}</td>
              <td>{alumno.periodoExamenDescripcion.trim() + " - " + alumno.periodoExamenLlamado.trim() + " - " + alumno.anioAcademicoDescripcion.trim()}</td>
              <td>{format(new Date(alumno.mesaExamenFechaExamen), 'dd/MM/yyyy')}</td>
              <td>{alumno.actaExamenEstado}</td>
              <td>{alumno.actaExamenTipo == 'R' ? "Regular" : alumno.actaExamenTipo == 'L' ? "Libre" : alumno.actaExamenTipo == 'E' ? "Equivalencia" : ""}</td>
              <td>{alumno.actaExamenLibro}</td>
              <td>{alumno.actaExamenFolio}</td>
              <td>
                <ButtonGroup>
                  <Button className="editButton" size="sm" tag={Link} to={"/aex/watch/" + alumno.actaExamenNacta} >Ver Acta</Button>
                  {alumno.actaExamenEstado.trim() == "ISN" && <Button className="editButton" size="sm" color="primary" tag={Link} to={"/aex/load/" + alumno.actaExamenNacta} >Cargar Resultados</Button>}
                  {alumno.actaExamenEstado.trim() == "Cargada" && <Button className="editButton" size="sm" color="danger" onClick={() => this.handleRemoveItem(alumno.actaExamenNacta)}>Cerrar</Button>}
                  {alumno.actaExamenEstado.trim() == "Pendiente" && <Button className="editButton" size="sm" color="warning" onClick={() => this.imprimirActa(alumno.actaExamenNacta)}>Imprimir Acta</Button>}
                  <Button className="editButton" size="sm" tag={Link} to={"/update-eae/" + alumno.actaExamenNacta} >Modificar Encabezado</Button>
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.paginationData);

    return (
      <div>
        <h1>Manejar Datos de Actas de Examen</h1>
        {this.state.showErrorMessage ?
          <div>
            <span className="labelError"> {"Ocurrió un error al intentar ejecutar la operación." + this.state.errorMessage}</span>
          </div> :
          <div>
            <div className="pa2">
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                type="search"
                placeholder="Ingrese Nombre de la Materia o de la Carrera o el Estado del acta"
                onChange={this.handleChange}
              />
            </div>
            {contents}
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>}

      </div>
    );
  }

  async populateData() {
    const response = await fetch('api/aex/Index', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      data.returnModel.sort((a, b) => new Date(b.mesaExamenFechaExamen) - new Date(a.mesaExamenFechaExamen));
      const paginationData = data.returnModel.slice(0, this.state.perPage);
      this.setState({ alumnos: data.returnModel, searchData: data.returnModel, loading: false, pageCount: Math.ceil(data.returnModel.length / this.state.perPage), paginationData: paginationData });
    }
  }

  async ImprimirActa(id) {
    this.setState({ loading: true });
    const response = await fetch('api/aex/print/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      const byteCharacters = atob(data.returnModel);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'actaExamen.pdf';
      link.click();
      this.populateData();
    }
  }

  async deleteItem(id) {
    this.setState({ loading: true });
    const response = await fetch('api/aex/cerrar/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.populateData();
    }
  }
}
