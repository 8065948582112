import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { format } from "date-fns";

export class VerActaExamen extends Component { /*MD= Manejar Datos*/
  static displayName = VerActaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchData: [],
      searchInput: "",
      perPage: 10,
      paginationData: [],
      offset: 0,
      showErrorMessage: false,
      errorMessage: "",
      alumnos: [],
      materia: "",
      id: this.props.match.params.id,
      resultados: [],
      folio: "",
      libro: "",
      fechaExamen: new Date().toString(),
      tipoActa: ""
    };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  handleConfirmButton = () => {
    this.setState({ success: true });
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWActasExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.alumnos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleChange = (e, id) => {
    const index = this.state.resultados.findIndex(x => x.id === e);

    if (index > 0) {
      const element = this.state.resultados[index];
      element.resultado = id.value;
      this.state.resultados.splice(index, 1, element);
    } else {
      this.state.resultados.push({ id: e, resultado: id.value });
    }
  }

  allRowsWereSaved() {
    let todoOk = true;

    this.state.alumnos.forEach(y => {
      const index = this.state.resultados.findIndex(x => x.id === y.detalleActaCursadaCod);

      if (index < 0) {
        todoOk = false;
        return;
      }
    });

    return todoOk;
  }

  allDataPopulated() {
    let todoOk = true;

    this.state.resultados.forEach(x => {
      if (x.resultado === '') {
        todoOk = false;
        return;
      }
    })

    return todoOk;
  }

  handleStatus = (resultado) => {
    if (resultado == 'R') {
      return 'Regular';
    } else if (resultado == 'L') {
      return 'Libre';
    } else {
      return '';
    }
  }

  renderalumnosTable(alumnos) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>DNI</th>
            <th>Nombre y Apellido</th>
            <th>Nota Examen Oral</th>
            <th>Nota Examen Escrito</th>
            <th>Nota Examen Promedio</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          {alumnos.map(alumno =>
            <tr key={alumno.detalleActaCod}>
              <td>{alumno.alumnoDni}</td>
              <td>{alumno.alumnoNombre}</td>
              <td>{alumno.detalleActaOral}</td>
              <td>{alumno.detalleActaEscrito}</td>
              <td>{alumno.detalleActaNota}</td>
              <td>{alumno.detalleActaResultado}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.searchData);

    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Ver Acta de Examen</h1>
            <div>
              {this.state.showErrorMessage &&
                <div>
                  <span className="labelError"> {this.state.errorMessage}</span>
                </div>
              }
            </div>
            <div>
              <h4>Materia: {this.state.materia}</h4>
              <h6>Tipo Acta: {this.state.tipoActa == 'R' ? "Regular" : this.state.tipoActa == 'L' ? "Libre" : this.state.tipoActa == 'E' ? "Equivalencia" : ""}</h6>
              <h6>Fecha Examen: {format(new Date(this.state.fechaExamen), 'dd/MM/yyyy')}</h6>
              <div>
                <label> Libro:   {this.state.libro}  Número de Folio: {this.state.folio}</label>
              </div>
              <div className="gsdgjasf">
                <ButtonGroup>
                  <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
                </ButtonGroup>
              </div>
              <div>
                {contents}
              </div>
            </div>
          </div>
          : <Redirect to="/md-aex" />}
      </div >
    );
  }

  async populateData() {
    const response = await fetch('api/aex/detalle/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.setState({
        alumnos: data.returnModel,
        folio: data.returnModel[0].actaExamenFolio,
        libro: data.returnModel[0].actaExamenLibro,
        materia: data.returnModel[0].materiaCarreraNombre.trim() + " - " +  data.returnModel[0].carreraNombre.trim(),
        searchData: data.returnModel,
        fechaExamen: data.returnModel[0].mesaExamenFechaExamen,
        tipoActa: data.returnModel[0].actaExamenTipo, 
        loading: false
      });
    }
  }

  async postNotas() {
    this.setState({ loading: true });
    const objects = [];
    this.state.resultados.map((tag) => {
      objects.push({ DetalleActaCursadaCod: tag.id, DetalleActaCursadaResultado: tag.resultado });
    });
    const response = await fetch('api/acur',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify(objects)
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.setState({ success: true });
    }
  }
}
