import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { MDAlumnos } from './components/Alumnos/MDAlumnos';
import { MDCarreras } from './components/Carreras/MDCarreras';
import { MDAlumnoxCarrera } from './components/AlumnoCarrera/MDAlumnoxCarrera';
import { MDAnioAcademicos } from './components/AnioAcademico/MDAnioAcademicos';
import { MDMateriaCarrera } from './components/MateriaCarrera/MDMateriaCarrera';
import { MDPeriodoExamen } from './components/PeriodoExamen/MDPeriodoExamen';
import { MDCursadas } from './components/Cursadas/MDCursadas';
import { MDPeriodoCursada } from './components/PeriodoCursada/MDPeriodoCursada';
import { MDInscripcionCursada } from './components/InscripcionCursada/MDInscripcionCursada';
import { MDInscripcionExamen } from './components/InscripcionExamen/MDInscripcionExamen';
import { MDMesaExamen } from './components/MesaExamen/MDMesaExamen';
import { MDHistoriaAcademicaCursada } from './components/HistoriaAcademicaCursada/MDHistoriaAcademicaCursada';
import { MDActasCursadas } from './components/ActaCursada/MDActasCursadas';
import { MDHistoriaAcademicaExamen } from './components/HistoriaAcademicaExamen/MDHistoriaAcademicaExamen';
import { MDActaExamen } from './components/ActaExamen/MDActaExamen';
import { AddAlumnos } from './components/Alumnos/AddAlumnos';
import { AddCarrera } from './components/Carreras/AddCarrera';
import { AddAnioAcademico } from './components/AnioAcademico/AddAnioAcademico';
import { AddMateria } from './components/MateriaCarrera/AddMateria';
import { AddPeriodoExamen } from './components/PeriodoExamen/AddPeriodoExamen';
import { AddAlumnoCarrera } from './components/AlumnoCarrera/AddAlumnoCarrera';
import { AddMesaExamen } from './components/MesaExamen/AddMesaExamen';
import { AddInscripcionCursada } from './components/InscripcionCursada/AddInscripcionCursada';
import { AddInscripcionCursadaAlumnos } from './components/InscripcionCursada/AddInscripcionCursadaAlumnos';
import { AddCursada } from './components/Cursadas/AddCursada';
import { AddPeriodoCursada } from './components/PeriodoCursada/AddPeriodoCursada';
import { AddInscripcionExamen } from './components/InscripcionExamen/AddInscripcionExamen';
import { UpdateAlumno } from './components/Alumnos/UpdateAlumno';
import { UpdateCarrera } from './components/Carreras/UpdateCarrera';
import { UpdateAlumnoCarrera } from './components/AlumnoCarrera/UpdateAlumnoCarrera';
import { UpdateMateria } from './components/MateriaCarrera/UpdateMateria';
import { UpdateAnioAcademico } from './components/AnioAcademico/UpdateAnioAcademico';
import { UpdatePeriodoExamen } from './components/PeriodoExamen/UpdatePeriodoExamen';
import { UpdateCursada } from './components/Cursadas/UpdateCursada';
import { UpdatePeriodoCursada } from './components/PeriodoCursada/UpdatePeriodoCursada';
import { UpdateInscripcionCursada } from './components/InscripcionCursada/UpdateInscripcionCursada';
import { UpdateMesaExamen } from './components/MesaExamen/UpdateMesaExamen';
import { UpdateInscripcionExamen } from './components/InscripcionExamen/UpdateInscripcionExamen';
import { UpdateHistoriaAcademicaCursada } from './components/HistoriaAcademicaCursada/UpdateHistoriaAcademicaCursada';
import { UpdateHistoriaAcademicaExamen } from './components/HistoriaAcademicaExamen/UpdateHistoriaAcademicaExamen';
import { UpdateEncabezado } from './components/ActaExamen/UpdateEncabezado';
import { Counter } from './components/Counter';
import { GenerarCursadas } from './components/Cursadas/GenerarCursadas';
import { InscribirAlumno } from './components/AlumnoCarrera/InscribirAlumno';
import { CargarActaCursada } from './components/ActaCursada/CargarActaCursada';
import { CargarActaExamen } from './components/ActaExamen/CargarActaExamen';
import { VerActaCursada } from './components/ActaCursada/VerActaCursada';
import { VerActaExamen } from './components/ActaExamen/VerActaExamen';
import { GenerateEstadoCurricular } from './components/EstadoCurriculares/GenerateEstadoCurricular';
import { MDInscripcionCursadaAlumno } from './components/InscripcionCursada/MDInscripcionCursadaAlumno';
import { UpdateInscripcionCursadaAlumno } from './components/InscripcionCursada/UpdateInscripcionCursadaAlumno';
import { ValidarInscripcionCursadas } from './components/InscripcionCursada/ValidarInscripcionCursadas';
import { CargarMesas } from './components/MesaExamen/CargarMesas';
import { InscribirAlumnosPrimero } from './components/Alumnos/InscribirAlumnosPrimero';
import { ActualizarRegularidades } from './components/HistoriaAcademicaCursada/ActualizarRegularidades';

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/accur' component={ActualizarRegularidades} />
        <Route path='/md-alumnos' component={MDAlumnos} />
        <Route path='/md-ica' component={MDInscripcionCursadaAlumno} />
        <Route path='/md-carreras' component={MDCarreras} />
        <Route path='/md-axc' component={MDAlumnoxCarrera} />
        <Route path='/md-mxc' component={MDMateriaCarrera} />
        <Route path='/md-anios' component={MDAnioAcademicos} />
        <Route path='/md-pex' component={MDPeriodoExamen} />
        <Route path='/md-pcr' component={MDPeriodoCursada} />
        <Route path='/md-cur' component={MDCursadas} />
        <Route path='/md-ic' component={MDInscripcionCursada} />
        <Route path='/md-ie' component={MDInscripcionExamen} />
        <Route path='/md-mex' component={MDMesaExamen} />
        <Route path='/gen-cur' component={GenerarCursadas} />
        <Route path='/md-acur' component={MDActasCursadas} />
        <Route path='/md-hic' component={MDHistoriaAcademicaCursada} />
        <Route path='/md-hex' component={MDHistoriaAcademicaExamen} />
        <Route path='/md-aex' component={MDActaExamen} />
        <Route path='/add-alumno' component={AddAlumnos} />
        <Route path='/add-carrera' component={AddCarrera} />
        <Route path='/add-axc' component={AddAlumnoCarrera} />
        <Route path='/add-mxc' component={AddMateria} />
        <Route path='/add-anio' component={AddAnioAcademico} />
        <Route path='/add-pex' component={AddPeriodoExamen} />
        <Route path='/add-pcr' component={AddPeriodoCursada} />
        <Route path='/add-cur' component={AddCursada} />
        <Route path='/add-ic' component={AddInscripcionCursada} />
        <Route path='/add-ica' component={AddInscripcionCursadaAlumnos} />
        <Route path='/add-ie' component={AddInscripcionExamen} />
        <Route path='/add-mex' component={AddMesaExamen} />
        <Route path='/update-alumno/:id/:details' component={UpdateAlumno} />
        <Route path='/update-carrera/:id/:details' component={UpdateCarrera} />
        <Route path='/update-axc/:id/:details' component={UpdateAlumnoCarrera} />
        <Route path='/update-mxc/:id/:details' component={UpdateMateria} />
        <Route path='/update-anio/:id/:details' component={UpdateAnioAcademico} />
        <Route path='/update-pex/:id/:details' component={UpdatePeriodoExamen} />
        <Route path='/update-pcr/:id/:details' component={UpdatePeriodoCursada} />
        <Route path='/update-cur/:id/:details' component={UpdateCursada} />
        <Route path='/update-ic/:id/:details' component={UpdateInscripcionCursada} />
        <Route path='/update-mex/:id/:details' component={UpdateMesaExamen} />
        <Route path='/update-ie/:id/:details' component={UpdateInscripcionExamen} />
        <Route path='/update-hic/:id/:details' component={UpdateHistoriaAcademicaCursada} />
        <Route path='/update-hex/:id/:details' component={UpdateHistoriaAcademicaExamen} />
        <Route path='/update-ica/:id/:details' component={UpdateInscripcionCursadaAlumno} />
        <Route path='/update-eae/:id' component={UpdateEncabezado} />
        <Route path='/axc-inscribir' component={InscribirAlumno} />
        <Route path='/acur/load/:id' component={CargarActaCursada} />
        <Route path='/aex/load/:id' component={CargarActaExamen} />
        <Route path='/acur/watch/:id' component={VerActaCursada} />
        <Route path='/aex/watch/:id' component={VerActaExamen} />
        <Route path='/ecur' component={GenerateEstadoCurricular} />
        <Route path='/vic' component={ValidarInscripcionCursadas} />
        <Route path='/cme' component={CargarMesas} />
        <Route path='/iap' component={InscribirAlumnosPrimero} />
      </Layout>
    );
  }
}
