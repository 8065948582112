import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { NavMenuAlumno } from './NavMenuAlumno';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);

    this.state = {
      auth: Cookies.get('authToken'),
      alumnoCodigos: Cookies.get('alumnoCodigos')
    };
  }


  render() {
    window.addEventListener("authenticated", () => {
      const authToken = Cookies.get('authToken');
      console.log(authToken);
      this.setState({ auth: authToken });
      if (authToken == 'false')
      {
        window.dispatchEvent(new CustomEvent('logout'));  
      }
    });   
    var alumnoCodigos = Cookies.get('alumnoCodigos')?.split(','); 
    return (
      <div>
        {this.state.auth == 'true' ?
          alumnoCodigos.length == 1 && alumnoCodigos[0] == -1?
            <NavMenu /> :
            <NavMenuAlumno />
          :
          <Redirect to="/" />}
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
