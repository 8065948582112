import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupAnioAcademicos } from '../AnioAcademico/LookupAnioAcademicos';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

registerLocale('es', es)

export class UpdatePeriodoCursada extends Component {
  static displayName = UpdatePeriodoCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      tagData: [{ id: "", text: "" }],
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate: new Date(),
      llamado: "",
      anio: { anioAcademicoCod: 0, anioAcademicoDescripcion: "" },
      id: this.props.match.params.id,
      details: this.props.match.params.details
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEndInscripcionDate = this.handleEndInscripcionDate.bind(this);
    this.handleStartInscriptionDate = this.handleStartInscriptionDate.bind(this);
    this.getPeriodoCursada = this.getPeriodoCursada.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWPeriodoCursada');
    if (canAccess) {
      this.populateData();
    }
  }
  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      var errorMessage = "";
      var datesOk = this.areDatesOk();
      var inscriptionDatesOk = this.areInscriptionDateOk();
      var isOkToSave = this.isOkToSave();
      var inscriptionDateInsidePeriodDates = this.areInscriptionDateInsidePeriodDates();

      if (!isOkToSave) {
        errorMessage = "Todos los campos son obligatorios";
        this.setState({ showErrorMessage: true });
      }

      if (!datesOk) {
        if (errorMessage.length > 0) {
          errorMessage = errorMessage + ", la fecha de inicio no puede ser posterior a la fecha de fin";
        } else {
          errorMessage = "La fecha de inicio no puede ser posterior a la fecha de fin"
        }
        this.setState({ showErrorMessage: true });
      }

      if (!inscriptionDatesOk) {
        if (errorMessage.length > 0) {
          errorMessage = errorMessage + ", la fecha de inicio de inscripción no puede ser posterior a la fecha de fin";
        } else {
          errorMessage = "La fecha de inicio de inscripción no puede ser posterior a la fecha de fin"
        }
        this.setState({ showErrorMessage: true });
      }

      if (!inscriptionDateInsidePeriodDates) {
        if (errorMessage.length > 0) {
          errorMessage = errorMessage + ", las fechas de inscripción deben estar dentro del rango de fechas ingresado para el período de cursada";
        } else {
          errorMessage = "Las fechas de inscripción deben estar dentro del rango de fechas ingresado para el período de cursada"
        }
        this.setState({ showErrorMessage: true });
      }

      if (isOkToSave && datesOk && inscriptionDatesOk && inscriptionDateInsidePeriodDates) {
        this.saveStudent();
      } else {
        this.setState({ errorMessage: errorMessage });
      }
    }
  }

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "1") {
      return "Primer Cuatrimestre";
    } else {
      if (periodoCursadaDescripcion == "2") {
        return "Segundo Cuatrimestre";
      }
      else {
        return "Anual";
      }
    }
  }

  async populateData() {
    const response = await fetch('api/pcr/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    this.setState(
      {
        errorMessage: errorMessage,
        anio: {
          anioAcademicoCod: data.returnModel.anioAcademicoCod,
          anioAcademicoDescripcion: data.returnModel.anioAcademicoDescripcion
        },
        descripcion: data.returnModel.periodoCursadaDescripcion.trim(),
        startDate: new Date(data.returnModel.periodoCursadaFechaInicio),
        endDate: new Date(data.returnModel.periodoCursadaFechaFin),
        startInscriptionDate: new Date(data.returnModel.periodoCursadaInicioInscripcio),
        endInscriptionDate: new Date(data.returnModel.periodoCursadaFinInscripcion),
      });
  }

  handleEndInscripcionDate = (date) => {
    this.setState({ endInscriptionDate: date });
  }

  handleStartInscriptionDate = (date) => {
    this.setState({ startInscriptionDate: date });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const anioProperties = e.split('%');
    this.setState({
      anio: {
        anioAcademicoCod: anioProperties[0],
        anioAcademicoDescripcion: anioProperties[1]
      }
    });
  }

  handleChange = (e) => {
    this.setState({ descripcion: e.value });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var options = [
      { value: '', label: '' },
      { value: 'A', label: 'Anual' },
      { value: '1', label: 'Primer Cuatrimestre' },
      { value: '2', label: 'Segundo Cuatrimestre' }
    ];
    var defaultValue = options.filter(x => x.value == this.state.descripcion)[0];
    var title = disable ? "Ver datos de Período de Cursada" : "Modificar Período de Cursada";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">

              {!disable ?
                <div>
                  <label> Seleccione el Período de Cursada: </label>
                  <Select
                    options={options}
                    value={defaultValue}
                    disabled={this.state.loading || disable}
                    onChange={this.handleChange}
                  /></div>
                :
                <div>
                  <label> Período de Cursada: </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    placeholder="Ingrese Período de Cursada"
                    onChange={this.handleDescripcionChangeInput}
                    disabled={this.state.loading || disable}
                    value={this.getPeriodoCursada(this.state.descripcion)}
                    required
                  />
                </div>}
            </div>
            <div>
              {!disable ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Año Académico</Button> :
                <label> Datos del Año Académico </label>}
              {this.state.showLookupAnios ? <LookupAnioAcademicos selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.anio.anioAcademicoDescripcion}
              />
            </div>

            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Inicio: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.startDate}
                    onChange={this.handleStartDate}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Inicio </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.startDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Fin: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.endDate}
                    onChange={this.handleEndDate}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                : <div>
                  <label> Fecha de Fin </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.endDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Inicio de Inscripción: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.startInscriptionDate}
                    onChange={this.handleStartInscriptionDate}
                    disabled={this.state.loading || disable}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Inicio de Inscripción </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.startInscriptionDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Fin de Inscripción: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.endInscriptionDate}
                    onChange={this.handleEndInscripcionDate}
                    disabled={this.state.loading || disable}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                : <div>
                  <label> Fecha de Fin de Inscripción </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.endInscriptionDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable? (
                <Button size="sm" tag={Link} to={"/md-pcr"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-pcr" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.descripcion.length > 0 && this.state.anio != null && this.state.anio.anioAcademicoDescripcion.length > 0 && this.state.startDate != null && this.state.endDate != null && this.state.endInscriptionDate != null && this.state.startInscriptionDate != null);
  }

  areDatesOk() {
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);

    return (endDate > startDate);
  }

  areInscriptionDateOk() {
    const startInscripcionDate = new Date(this.state.startInscriptionDate);
    const endInscriptionDate = new Date(this.state.endInscriptionDate);

    return (endInscriptionDate > startInscripcionDate);
  }

  areInscriptionDateInsidePeriodDates() {
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);
    const startInscripcionDate = new Date(this.state.startInscriptionDate);
    const endInscriptionDate = new Date(this.state.endInscriptionDate);

    return (endDate >= startInscripcionDate) && (startInscripcionDate >= startDate) && (endDate >= endInscriptionDate) && (endInscriptionDate >= startDate);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/pcr',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          PeriodoCursadaCod: parseInt(this.state.id),
          PeriodoCursadaDescripcion: this.state.descripcion,
          PeriodoCursadaFechaInicio: this.state.startDate,
          PeriodoCursadaFechaFin: this.state.endDate,
          PeriodoCursadaInicioInscripcio: this.state.startInscriptionDate,
          PeriodoCursadaFinInscripcion: this.state.endInscriptionDate,
          AnioAcademicoCod: parseInt(this.state.anio.anioAcademicoCod)
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
