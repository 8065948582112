import es from 'date-fns/locale/es';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { LookupAlumnoCarrera } from '../AlumnoCarrera/LookupAlumnoCarrera';
import '../Buttons.css';
import { LookupCursadas } from '../Cursadas/LookupCursadas';
import * as constantClass from '../fileWithConstants';

registerLocale('es', es)

export class AddInscripcionCursada extends Component {
  static displayName = AddInscripcionCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      showCursada: false,
      tagDataAlumno: [{ id: "", text: "" }],
      tagDataCursada: [{ id: "", text: "" }],
      alumno: { codigo: "", descripcion: "" },
      cursada: { codigo: "", descripcion: "" },
      startDate: new Date(),
      endDate: new Date(),
      startInscriptionDate: new Date(),
      endInscriptionDate: new Date(),
      carreraCursada: -1,
      materiaCodigo: "",
      ignorarCorrelatividad: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleShowCursadaLookup = this.handleShowCursadaLookup.bind(this);
    this.handleSelectCursadaItem = this.handleSelectCursadaItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHideLookup1 = this.handleHideLookup1.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handleConfirmButton = () => {
    var isOkToSave = this.isOkToSave();

    if (!isOkToSave) {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    } else {
      this.saveStudent();
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWInscripcionCursada');
  }

  handleChange = (e) => {
    this.setState({ llamado: e.value });
  }

  handleDelete = (i) => {
    this.setState({ tagDataAlumno: this.state.tagDataAlumno.filter(tag => tag.id !== i), carreraCursada: -1 });
  }

  handleDeleteCursada = (i) => {
    this.setState({ tagDataCursada: this.state.tagDataCursada.filter(tag => tag.id !== i) });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleShowCursadaLookup = () => {
    this.setState({ showCursada: true });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }
  handleHideLookup1 = () => {
    this.setState({ showCursada: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupAnios: false,
      carreraCursada: Number(carreraProperties[3]),
      alumno: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1] + " - " + carreraProperties[2]
      }
    });
  }

  handleCheckBox = (e) => {
    this.setState({ ignorarCorrelatividad: e.target.checked });
  }

  handleSelectCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showCursada: false,
      materiaCodigo: carreraProperties[5],
      cursada: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[4].trim()
      }
    });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Inscripción a Cursada</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>

              {!this.state.loading ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Alumno</Button>
                : <label> Datos del Alumno </label>}
              {this.state.showLookupAnios ? <LookupAlumnoCarrera selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.descripcion}
              />
            </div>
            <div>
              {!this.state.loading && this.state.carreraCursada != -1 ? <Button color="link" onClick={this.handleShowCursadaLookup}> Seleccione la Cursada</Button>
                : <label> Seleccione el Alumno antes de seleccionar la materia </label>}
              {this.state.showCursada ? <LookupCursadas carreraCod={this.state.carreraCursada} selectItem={this.handleSelectCursadaItem} hideLookup={this.handleHideLookup1} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.cursada.descripcion}
              />
            </div>
            <div>
              <input type="checkbox" onClick={this.handleCheckBox} value={ this.state.ignorarCorrelatividad} /> Ignorar verificación de correlatividad
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-ic"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-ic" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.alumno.descripcion.length > 1 && this.state.cursada.descripcion.length > 1);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/ic',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          CursadaCod: this.state.cursada.codigo,
          AlumnoxCarreraCod: this.state.alumno.codigo,
          MateriaCarreraCod5: this.state.materiaCodigo,
          IgnorarCorrelatividad: this.state.ignorarCorrelatividad
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
