import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';
import { LookupCarreras } from '../Carreras/LookupCarreras';

export class InscribirAlumno extends Component {
  static displayName = InscribirAlumno.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      tagData: [{ id: "", text: "" }],
      showLookupCarreras: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
  }

  handleDNIChangeInput = e => {
    this.setState({ dni: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveStudent();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Los campos Alumno y Carrera son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWAlumnosxCarrera');
  }

  handleHideLookup = () => {
    this.setState({
      showLookupCarreras: false
    })
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    const tag = { id: carreraProperties[0], text: carreraProperties[1] }
    const tagData = this.state.tagData;
    tagData.push(tag);
    this.setState({ showLookupCarreras: false, tagData: tagData });
  }

  rendleTags() {
    var tagData = this.state.tagData.slice(1);
    return (
      <div>
        {tagData.length > 0 ?
          tagData.map((tag) => (
            <li key={tag.id} className="tag">
              <span className="tag-title">{tag.text}</span>
              <span
                className="tag-close-icon"
                onClick={() => this.handleDelete(tag.id)}
              >
                x
                     </span>
            </li>
          ))
          : ""}
      </div>
    );
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupCarreras: true });
  }

  handleDelete = (i) => {
    this.setState({ tagData: this.state.tagData.filter(tag => tag.id !== i) });
  }

  render() {
    var content = this.rendleTags();
    return (
        <div>
        <h1>Nuevo Alumno</h1>
        <div>
          <span className="labelSuccess"> {this.state.success ? "Los datos se almacenaron con éxito" : ""}</span>
          <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading && this.state.dni.length>0 ? this.state.errorMessage : ""}</span>
          <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
          <div className="pa2">
            <label> Ingrese datos del Alumno </label>
            <input
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
              name="name" size="30"
              placeholder="Ingrese DNI"
              onChange={this.handleDNIChangeInput}
              disabled={this.state.loading}
              value={this.state.dni}
              required
            />

            <input
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
              name="name" size="30"
              placeholder="Ingrese Nombre Completo"
              onChange={this.handleNameChangeInput}
              disabled={this.state.loading}
              value={this.state.name}
              required
            />

          </div>
          <div>
            {!this.state.loading ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la carrera</Button>
              : <label> Seleccione la carrera </label>}
            {this.state.showLookupCarreras ? <LookupCarreras selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup } /> : ""}
            <div className="tag-input">
              <ul className="tags">
                {content}
              </ul>
            </div>
          </div>
          <ButtonGroup>
            {!this.state.loading ? (
              <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
            ) : (
              <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    );
  }

  isOkToSave() {
    const tags = this.state.tagData.slice(1);
    return (this.state.dni.length > 0 && this.state.name.length > 0 && tags.length > 0);
  }

  async saveStudent() {
    var object = [];
    this.state.tagData.slice(1).map((tag) => {
      object.push(parseInt(tag.id));
    });
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/axc/inscribir',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({ AlumnoNombre: this.state.name, AlumnoDni: this.state.dni, CarrerasCod: object })
      });
    const data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      this.setState({ loading: false, success: false, errorMessage: errorMessage });
    } else {
      this.setState({
        loading: false,
        success: true,
        errorMessage: "",
        dni: "",
        name: "",
        tagData: [{ id: "", text: "" }],
        showLookupCarreras: false
      });
    }
    
  }
}
