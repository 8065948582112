import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';
import { LookupPeriodoCursada } from '../PeriodoCursada/LookupPeriodoCursada';
import { LookupMaterias } from '../MateriaCarrera/LookupMaterias';

export class AddCursada extends Component {
  static displayName = AddCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      tagDataMateria: [{ id: "", text: "" }],
      tagDataPeriodoCursada: [{ id: "", text: "" }],
      alumno: { alumnoDni: "", alumnoCodigo: 0, alumnoNombre: "" },
      materia: { materiaCarreraCodigo: "", descripcion: "" },
      cursada: { cursadacod: "", cursadadescripcion: "" },
      showLookupMaterias: false,
      showLookupPeriodoCursada: false,
      practica: "",
      value: { value: '', label: '' },
      valueDictado: { value: '', label: '' },
      name: ""
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeletePeriodoCursada = this.handleDeletePeriodoCursada.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleAnioChangeInput = this.handleAnioChangeInput.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleSelectPeriodoCursadaItem = this.handleSelectPeriodoCursadaItem.bind(this);
    this.handleShowPeriodoCursadaLookup = this.handleShowPeriodoCursadaLookup.bind(this);
    this.handleHidePeriodoCursadaLookup = this.handleHidePeriodoCursadaLookup.bind(this);
  }

  handleConfirmButton = () => {
    var dictadoCorrecto = this.state.dictado == this.state.anioDictado;
    var okToSave = this.isOkToSave();

    if (okToSave && dictadoCorrecto) {
      this.saveStudent();
    } else {
      var errorMessage = "";
      if (!okToSave) {
        errorMessage = "Todos los campos son obligatorios";
      } else {
        errorMessage = "La materia seleccionada no se dicta en el período seleccionado";
      }
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    }
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    const dictado = carreraProperties[4]
    this.setState({
      showLookupMaterias: false,
      dictado: dictado,
      materia: {
        materiaCarreraCodigo: carreraProperties[0],
        descripcion: carreraProperties[1] + " - " + carreraProperties[2] + " - " + carreraProperties[3]
      }
    });
  }

  handleSelectPeriodoCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    const periodo = carreraProperties[3]
    this.setState({
      showLookupPeriodoCursada: false,
      anioDictado: periodo,
      cursada: {
        cursadacod: carreraProperties[0],
        cursadadescripcion: carreraProperties[1] + " - " + carreraProperties[2]
      }
    });
  }

  async componentDidMount() {
    await constantClass.canAccess('WWCursada');
  }

  handleSelect = e => {
    this.setState({ practica: e.value, value: e });
  }

  handleHideLookup = () => {
    this.setState({ showLookupCarreras: false });
  }

  handleHidePeriodoCursadaLookup = () => {
    this.setState({ showLookupPeriodoCursada: false });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupMaterias: true });
  }

  handleShowPeriodoCursadaLookup = () => {
    this.setState({ showLookupPeriodoCursada: true });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleAnioChangeInput = e => {
    this.setState({ anioDictado: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado: e });
  }

  handleCodeChange = e => {
    this.setState({ code: e.target.value });
  }

  handleDelete = (i) => {
    this.setState({ tagDataMateria: this.state.tagDataMateria.filter(tag => tag.id !== i) });
  }

  handleDeletePeriodoCursada = (i) => {
    this.setState({ tagDataPeriodoCursada: this.state.tagDataPeriodoCursada.filter(tag => tag.id !== i) });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nueva Cursada</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la materia</Button>
                : <label> Seleccione la materia </label>}
              {this.state.showLookupMaterias ? <LookupMaterias selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.materia.descripcion}
              />
            </div>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowPeriodoCursadaLookup}> Seleccione el período de cursada</Button>
                : <label> Seleccione el período de cursada </label>}
              {this.state.showLookupPeriodoCursada ? <LookupPeriodoCursada selectItem={this.handleSelectPeriodoCursadaItem} hideLookup={this.handleHidePeriodoCursadaLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.cursada.cursadadescripcion}
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-cur"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-cur" />
        }
      </div>
    );
  }

  isOkToSave() {

    return (this.state.materia.descripcion.length > 0 && this.state.cursada.cursadadescripcion.length > 0);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/cur',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          MateriaCarreraCod: this.state.materia.materiaCarreraCodigo,
          PeriodoCursadaCod: this.state.cursada.cursadacod
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
