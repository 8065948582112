import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Pagination.css';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

export class MDCarreras extends Component { /*MD= Manejar Datos*/
  static displayName = MDCarreras.name;

  constructor(props) {
    super(props);
    this.state = { loading: true, searchData: [], searchInput: "", perPage: 10, paginationData: [], offset: 0, showErrorMessage: false, errorMessage: "" };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWCarreras');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.carreras.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleChange = e => {
    if (e.target.value !== "") {
      this.setState({ searchInput: e.target.value, loading: true });
      const data = this.state.carreras.filter(
        carrera => {
          return (
            carrera
              .carreraNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            carrera
              .carreraCod
              .toString()
              .includes(e.target.value.toLowerCase())
          );
        }
      );
      var paginationData = data.slice(0, 0 + this.state.perPage);
      this.setState({ searchData: data, loading: false, paginationData: paginationData, pageCount: Math.ceil(data.length / this.state.perPage) });
    } else {
      var paginationData = this.state.carreras.slice(0, 0 + this.state.perPage);
      this.setState({ paginationData: paginationData, pageCount: Math.ceil(this.state.carreras.length / this.state.perPage), searchInput: "" });
    }
  }

  renderalumnosTable(carreras) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nombre</th>
            <th>Año Implementación</th>
            <th colSpan="4">Acción</th>
          </tr>
        </thead>
        <tbody>
          {carreras.map(carrera =>
            <tr key={carrera.carreraCod}>
              <td>{carrera.carreraCod}</td>
              <td>{carrera.carreraNombre}</td>
              <td>{carrera.carreraAnioImplementacion ? carrera.carreraAnioImplementacion:"2018"}</td>
              <td>
                <ButtonGroup>
                  <Button className="editButton" size="sm" color="primary" tag={Link} to={"/update-carrera/" + carrera.carreraCod+"/0"} >Modificar</Button>
                  <Button className="editButton" size="sm" color="danger" onClick={() => this.handleRemoveItem(carrera.carreraCod)}>Borrar</Button>
                  <Button className="editButton" size="sm" tag={Link} to={"/update-carrera/" + carrera.carreraCod + "/1"} >Detalles</Button>
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }


  handleItemSelect = id =>
  {
    console.log(id);
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.paginationData);

    return (
      <div>
        <h1>Manejar Datos de Carreras</h1>
        {this.state.showErrorMessage ?
          <div>
            <span className="labelError"> {"Ocurrió un error al intentar ejecutar la operación." + this.state.errorMessage}</span>
          </div> :
          <div>
            <p>
              <Link to="/add-carrera">Nueva Carrera</Link>
            </p>
            <div className="pa2">
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                type="search"
                placeholder="Ingrese Nombre de la Carrera"
                onChange={this.handleChange}
              />
            </div>
            {contents}
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>}

      </div>
    );
  }

  async populateData() {
    const response = await fetch('api/Carreras/Index',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      data.returnModel.sort((a, b) => b.carreraCod - a.carreraCod);
      const paginationData = data.returnModel.slice(0, this.state.perPage);
      this.setState({ carreras: data.returnModel, searchData: data.returnModel, loading: false, pageCount: Math.ceil(data.returnModel.length / this.state.perPage), paginationData: paginationData });
    }
  }

  async deleteItem(id) {
    this.setState({ loading: true });
    const response = await fetch('api/Carreras/delete/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.populateData();
    }
  }
}
