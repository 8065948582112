import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupMaterias } from '../MateriaCarrera/LookupMaterias';
import { LookupPeriodoExamen } from '../PeriodoExamen/LookupPeriodoExamen';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";


registerLocale('es', es)

export class UpdateMesaExamen extends Component {
  static displayName = UpdateMesaExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      periodo: { codigo: 0, descripcion: "", fechaInicioPeriodo: new Date(), fechafinPeriodo: new Date() },
      materia: { materiaCodigo: "", materiaCod: "", materiaNombre: "", carrera: "" },
      mesaExamen: { fechaExamen: new Date(), fechaInicioInscripcion: new Date(), fechaFinInscripcion: new Date() },
      showLookupPeriodoCursada: false,
      showLookupCarreras: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStudentHideLookup = this.handleStudentHideLookup.bind(this);
    this.handleShowStudentLookup = this.handleShowStudentLookup.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleSelectStudentItem = this.handleSelectStudentItem.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleFechaExamen = this.handleFechaExamen.bind(this);
    this.handleFechaInicioInscripcion = this.handleFechaInicioInscripcion.bind(this);
    this.handleFechaFinInscripcion = this.handleFechaFinInscripcion.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWMesaExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      var okToSave = this.isOkToSave();
      var fechasOk = this.datesOK();
      var fechasEnPeriodo = true;

      if (this.state.details == 1) {
        okToSave = true;
        fechasOk = true;
        fechasEnPeriodo = true;
      }

      if (okToSave && fechasOk && fechasEnPeriodo) {
        this.saveStudent();
      } else {
        var errorMessage = "";

        if (!okToSave) {
          errorMessage = "Todos los campos son obligatorios";
        } else
          if (!fechasOk) {
            errorMessage = "Compruebe las fechas ingresadas, las fechas de inscripción deben ser menor a la fecha del examen";
          } else {
            if (!fechasEnPeriodo) {
              errorMessage = "Compruebe que las fechas se encuentren dentro del período de examen seleccionado";
            }
          }
        this.setState({ showErrorMessage: true, errorMessage: errorMessage });
      }
    }
  }

  handleHideLookup = () => {
    this.setState({ showLookupMaterias: false });
  }

  handleStudentHideLookup = () => {
    this.setState({ showLookupPeriodoCursada: false });
  }

  handleShowStudentLookup = () => {
    this.setState({ showLookupPeriodoCursada: true });
  }

  handleShowCarreraLookup = () => {
    this.setState({ showLookupMaterias: true });
  }

  handleFechaExamen = (date) => {
    this.setState({ mesaExamen: { fechaExamen: new Date(date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()), fechaInicioInscripcion: this.state.mesaExamen.fechaInicioInscripcion, fechaFinInscripcion: this.state.mesaExamen.fechaFinInscripcion } });
  }

  handleFechaInicioInscripcion = (date) => {
    this.setState({
      mesaExamen: { fechaInicioInscripcion: new Date(date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()), fechaExamen: this.state.mesaExamen.fechaExamen, fechaFinInscripcion: this.state.mesaExamen.fechaFinInscripcion }
    });
  }

  handleFechaFinInscripcion = (date) => {
    this.setState({ mesaExamen: { fechaFinInscripcion: new Date(date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()), fechaExamen: this.state.mesaExamen.fechaExamen, fechaInicioInscripcion: this.state.mesaExamen.fechaInicioInscripcion } });
  }

  handleSelectStudentItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupPeriodoCursada: false,
      periodo: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[3].trim(),
        fechaInicioPeriodo: carreraProperties[4],
        fechaFinPeriodo: carreraProperties[5]
      }
    });
  }

  handleSelectCarreraItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupMaterias: false,
      materia: {
        materiaCodigo: carreraProperties[0].trim(),
        materiaCod: carreraProperties[1].trim(),
        materiaNombre: carreraProperties[2].trim(),
        carrera: carreraProperties[3].trim()
      }
    });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Mesa de Examen" : "Modificar Mesa de Examen";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>


            <div>
              {!disable ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la Materia</Button> :
                <label> Datos de la Materia </label>}
              {this.state.showLookupMaterias ? <LookupMaterias selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.materia.materiaCod + " - " + this.state.materia.materiaNombre + " - " + this.state.materia.carrera}
              />
            </div>

            <div>
              {!disable ? <Button color="link" onClick={this.handleShowStudentLookup}> Seleccione el Período de Examen</Button> :
                <label> Datos del Período de Examen </label>}
              {this.state.showLookupPeriodoCursada ? <LookupPeriodoExamen selectItem={this.handleSelectStudentItem} hideLookup={this.handleStudentHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.periodo.descripcion}
              />
            </div>

            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Examen: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.mesaExamen.fechaExamen}
                    onChange={this.handleFechaExamen}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Examen </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.mesaExamen.fechaExamen.toLocaleDateString()}
                  />
                </div>}
            </div>

            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Inicio de Inscripción: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.mesaExamen.fechaInicioInscripcion}
                    onChange={this.handleFechaInicioInscripcion}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Inicio </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.mesaExamen.fechaInicioInscripcion.toLocaleDateString()}
                  />
                </div>}
            </div>

            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Fin de Inscripción: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.mesaExamen.fechaFinInscripcion}
                    onChange={this.handleFechaFinInscripcion}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Inicio </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.mesaExamen.fechaFinInscripcion.toLocaleDateString()}
                  />
                </div>}
            </div>

            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-mex"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-mex" />}
      </div>
    );
  }

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion == "1") {
      return "Primer Cuatrimestre";
    } else {
      if (periodoCursadaDescripcion == "2") {
        return "Segundo Cuatrimestre";
      }
      else {
        return "Anual";
      }
    }
  }

  isOkToSave() {
    return (this.state.materia.materiaCodigo.length > 0 && this.state.periodo.descripcion.length > 0);
  }

  datesInPeriodo() {
    return (
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.mesaExamen.fechaExamen && new Date(this.state.periodo.fechafinPeriodo) >= this.state.mesaExamen.fechaExamen) &&
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.mesaExamen.fechaInicioInscripcion && new Date(this.state.periodo.fechafinPeriodo) >= this.state.mesaExamen.fechaInicioInscripcion) &&
      (new Date(this.state.periodo.fechaInicioPeriodo) <= this.state.mesaExamen.fechaFinInscripcion && new Date(this.state.periodo.fechafinPeriodo) >= this.state.mesaExamen.fechaFinInscripcion)
    );
  }

  datesOK() {
    return (this.state.mesaExamen.fechaInicioInscripcion <= this.state.mesaExamen.fechaFinInscripcion && this.state.mesaExamen.fechaFinInscripcion < this.state.mesaExamen.fechaExamen)
  }

  async populateData() {
    const response = await fetch('api/mex/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    var fechaExamen = new Date(data.returnModel.mesaExamenFechaExamen);
    var fechaInicioInscripcion = new Date(data.returnModel.mesaExamenFechaInicioInscripci);
    var fechaFinInscripcion = new Date(data.returnModel.mesaExamenFechaFinInscripcion);
    var fechaPeriodoInicio = new Date(data.returnModel.periodoExamenFechaInicio);
    var fechaPeriodoFin = new Date(data.returnModel.periodoExamenFechaFin);    
    this.setState(
      {
        errorMessage: errorMessage,
        periodo: {
          codigo: data.returnModel.peridoExamenCod,
          descripcion: data.returnModel.periodoExamenDescripcion.trim() + " - " +
            data.returnModel.periodoExamenLlamado.trim() + " - " +
            data.returnModel.anioAcademicoDescripcion,
          fechaInicioPeriodo: new Date(fechaPeriodoInicio.getMonth() + 1 + "-" + fechaPeriodoInicio.getDate() + "-" + fechaPeriodoInicio.getFullYear()),
          fechafinPeriodo: new Date(fechaPeriodoFin.getMonth() + 1 + "-" + fechaPeriodoFin.getDate() + "-" + fechaPeriodoFin.getFullYear()) 
        },
        materia: { 
          materiaCodigo: data.returnModel.materiaCarreraCod.trim(),
          materiaNombre: data.returnModel.materiaCarreraNombre.trim(),
          materiaCod: data.returnModel.materiaCarreraCod.trim(),
          carrera: data.returnModel.carreraNombre.trim()
        },
        mesaExamen: { 
          fechaExamen: new Date(fechaExamen.getMonth() + 1 + "-" + fechaExamen.getDate() + "-" + fechaExamen.getFullYear()),
          fechaInicioInscripcion: new Date(fechaInicioInscripcion.getMonth() + 1 + "-" + fechaInicioInscripcion.getDate() + "-" + fechaInicioInscripcion.getFullYear()),
          fechaFinInscripcion: new Date(fechaFinInscripcion.getMonth() + 1 + "-" + fechaFinInscripcion.getDate() + "-" + fechaFinInscripcion.getFullYear())
        }
      });
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/mex',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          MesaExamenCod: parseInt(this.state.id),
          PeridoExamenCod: this.state.periodo.codigo,
          MateriaCarreraCod: this.state.materia.materiaCodigo.trim(),
          InicioFechaInscripcion: this.state.mesaExamen.fechaInicioInscripcion.getFullYear() + '-'
            + ("0" + (this.state.mesaExamen.fechaInicioInscripcion.getMonth() + 1)).slice(-2) + '-' +
            ("0" + this.state.mesaExamen.fechaInicioInscripcion.getDate()).slice(-2),
          FinFechaInscripcion: this.state.mesaExamen.fechaFinInscripcion.getFullYear() + '-'
            + ("0" + (this.state.mesaExamen.fechaFinInscripcion.getMonth() + 1)).slice(-2) + '-' +
            ("0" + this.state.mesaExamen.fechaFinInscripcion.getDate()).slice(-2),
          FechaExamenInscripcion: this.state.mesaExamen.fechaExamen.getFullYear() + '-'
            + ("0" + (this.state.mesaExamen.fechaExamen.getMonth() + 1)).slice(-2) + '-' +
            ("0" + this.state.mesaExamen.fechaExamen.getDate()).slice(-2)
        })
      });
    var data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
