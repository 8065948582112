import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './Buttons.css';
import * as constantClass from './fileWithConstants';
import Cookies from 'js-cookie';

export class Home extends Component {
  static displayName = Home.name;
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      contrasenia: "",
      loading: false,
      errorMessage: "",
      sucess: Cookies.get('authToken')
    }
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
  }

  handleSelectCarreraItem = () => {
    if (this.isOkToSave()) {
      this.authenticateUser();
    } else {
      this.setState({ errorMessage: "Ingrese usuario y contraseña por favor" });
    }
  }

  handleDNIChangeInput = e => {
    this.setState({ usuario: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ contrasenia: e.target.value });
  }

  isOkToSave() {
    return (this.state.contrasenia.length > 0 && this.state.usuario.length > 0);
  }

  render() {
    window.addEventListener("logout", () => {
      this.setState({ sucess: Cookies.get('authToken'), usuario: "", contrasenia: "" });
      console.log("ddddd");
    });
    return (
      <div>       
        {!this.state.sucess || this.state.sucess == 'false' ?
          <div>
            <h1>Bienvenido al Sistema de Alumnos del Instituto Jean Piaget</h1>
            <div>
              <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
              <span className="labelMessage"> {this.state.loading ? "Estamos validando sus datos, espere unos segundos..." : ""}</span>
            </div>
            <div>
              <label> Ingrese su Nombre de Usuario: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                onChange={this.handleDNIChangeInput}
                disabled={this.state.loading}
                value={this.state.usuario}
              />
            </div>

            <div>
              <label> Ingrese su Contraseña: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                type="password"
                disabled={this.state.loading}
                value={this.state.contrasenia}
                onChange={this.handleNameChangeInput}
              />
            </div>

            <div>
              <Button color="link" onClick={this.handleSelectCarreraItem} > Ingresar</Button>
            </div>
          </div>
          : <div></div>}
      </div>
    );
  }

  async authenticateUser() {
    this.setState({ errorMessage: "", loading: true });
    const response = await fetch('api/User/Authenticate/' + this.state.usuario + '/' + this.state.contrasenia,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
        }
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    } else {
      if (data.returnModel.length == 0) {
        errorMessage = "Usuario y/o contraseña inválidos";
        success = false;
      } else {
        Cookies.set('authToken', true);
        Cookies.set('username', this.state.usuario);
        Cookies.set('alumnoCodigos', data.returnModel);
        window.dispatchEvent(new CustomEvent('authenticated'));
      }
    }
    this.setState({ loading: false, sucess: success, errorMessage: errorMessage });
  }
}
