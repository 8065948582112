import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)


export class GenerarCursadas extends Component {
  static displayName = GenerarCursadas.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      startDateAnual: new Date(),
      endDateAnual: new Date(),
      startDate1C: new Date(),
      endDate1C: new Date(),
      startDate2C: new Date(),
      endDate2C: new Date(),
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleStartDateAnual = this.handleStartDateAnual.bind(this);
    this.handleEndDateAnual = this.handleEndDateAnual.bind(this);
    this.handleStartDate1C = this.handleStartDate1C.bind(this);
    this.handleEndDate1C = this.handleEndDate1C.bind(this);
    this.handleStartDate2C = this.handleStartDate2C.bind(this);
    this.handleEndDate2C = this.handleEndDate2C.bind(this);
  }

  handleConfirmButton = () => {
    var okToSave = this.isOkToSave();

    if (okToSave) {
      this.saveStudent();
    } else {
      var errorMessage = "";
      if (!okToSave) {
        errorMessage = "Revise las fechas ingresadas";
      } 
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWCursada');
  }

  handleStartDateAnual = (date) => {
    this.setState({ startDateAnual: date });
  }

  handleEndDateAnual = (date) => {
    this.setState({ endDateAnual: date });
  }

  handleStartDate1C = (date) => {
    this.setState({ startDate1C: date });
  }

  handleEndDate1C = (date) => {
    this.setState({ endDate1C: date });
  }

  handleStartDate2C = (date) => {
    this.setState({ startDate2C: date });
  }

  handleEndDate2C = (date) => {
    this.setState({ endDate2C: date });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Generar Cursadas</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>
              <label> Seleccione la Fecha de Inicio de inscripción para el período anual: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.startDateAnual}
                onChange={this.handleStartDateAnual}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Fin de inscripción para el período Anual: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.endDateAnual}
                onChange={this.handleEndDateAnual}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Inicio de inscripción para el primer cuatrimestre: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.startDate1C}
                onChange={this.handleStartDate1C}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Fin de inscripción para el primer cuatrimestre: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.endDate1C}
                onChange={this.handleEndDate1C}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Inicio de inscripción para el segundo cuatrimestre: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.startDate2C}
                onChange={this.handleStartDate2C}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label> Seleccione la Fecha de Fin de inscripción  para el segundo cuatrimestre: </label>
              <DatePicker className="gsfsafsFSFSf"
                locale="es"
                closeOnScroll={true}
                selected={this.state.endDate2C}
                onChange={this.handleEndDate2C}
                disabled={this.state.loading}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-cur"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-cur" />
        }
      </div>
    );
  }

  isOkToSave() {
    const startDateAnual = new Date(this.state.startDateAnual);
    const endDateAnual = new Date(this.state.endDateAnual);
    const startDate1C = new Date(this.state.startDate1C);
    const endDate1C = new Date(this.state.endDate1C);
    const startDate2C = new Date(this.state.startDate2C);
    const endDate2C = new Date(this.state.endDate2C);

    return (startDateAnual <= endDateAnual && startDate1C <= endDate1C && startDate2C <= endDate2C);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/cur/gen',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          FechaInicioAnual: this.state.startDateAnual,
          FechaInicio1C: this.state.startDate1C,
          FechaInicio2C: this.state.startDate2C,
          FechaFinAnual: this.state.endDateAnual,
          FechaFin1C: this.state.endDate1C,
          FechaFin2C: this.state.endDate2C
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
