import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: '1', label: 'PSICOPEDAGOGÍA' },
  { value: '2', label: 'Prof. en Psicología' },
  { value: '3', label: 'PSICOPEDAGOGÍA - Transición' },
  { value: '4', label: 'Psicopedagogía' },
];

export class ActualizarRegularidades extends Component {
  static displayName = ActualizarRegularidades.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: "",
      name: "",
      anioDictado: "",
      dictado: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      tagData: [{id:"",text:""}],
      alumno: { alumnoDni: "", alumnoCodigo: 0, alumnoNombre: "" },
      carrera: { codigo: "", nombre: "" },
      showLookupStudent: false,
      showLookupCarreras: false,
      practica: "",
      value: { value: '', label: '' },
      valueDictado: { value: '', label: '' },
      name:""
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleAnioChangeInput = this.handleAnioChangeInput.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveStudent();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('HistoriaAcademicaCursada');
  }

  handleSelectCarreraItem = (e) =>
  {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupCarreras: false,
      carrera: {
        codigo: carreraProperties[0],
        nombre: carreraProperties[1]
      }
    });
  }

  handleSelect = e => {
    this.setState({ practica: e.value, value: e });
  }

  handleHideLookup = () =>
  {
    this.setState({showLookupCarreras:false});
  }

  handleShowCarreraLookup = () =>
  {
    this.setState({showLookupCarreras:true});
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleAnioChangeInput = e => {
    this.setState({ anioDictado: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ dictado: e.value, valueDictado:e });
  }

  handleCodeChange = e => {
    this.setState({ code: e.target.value });
  }

  handleDelete = (i) => {
    this.setState({tagData:this.state.tagData.filter(tag =>tag.id !== i)});
  }

  render() {
    return (
      <div>
          <div>
            <h1>Actualizar Regularidades por Carrera</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              
            <div>
              <label> Carrera: </label>
              <div className="dropdownDictado">
                <Select
                  value={this.state.valueDictado}
                  options={optionsCuatrimestre}
                  onChange={this.handleDictadoChangeInput}
                />
              </div>
            </div>
           
              </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-hic"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
      </div>
    );
  }

  isOkToSave() {
    return (this.state.dictado.length>0);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/ic/actcur/' + this.state.dictado,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
