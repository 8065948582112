import React, { Component } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Container, Navbar } from 'reactstrap';
import './NavMenu.css';
import Cookies from 'js-cookie';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavDropdown className="text-primary my-auto" title="Usuario" >
              {/*Cambiar Contraseña*/}
              <NavDropdown.Item style={{ width: '5vw' }} onClick={() =>
              {
                Cookies.set('authToken', false);
                Cookies.set('alumnoCodigos', "");
                window.dispatchEvent(new CustomEvent('authenticated'));                
              }}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="text-primary my-auto" title="Alumnos" >
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-alumnos">Manejar datos de Alumnos</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-axc">Manejar datos de Alumnos por Carrera</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/axc-inscribir">Inscribir Alumnos</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/ecur">Generar Estado Curricular</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="text-primary my-auto" title="Carreras" >
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-carreras">Manejar datos de Carreras</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-mxc">Manejar datos de Materias</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="text-primary my-auto" title="Cursadas" >
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-cur">Manejar datos de Cursadas</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-ic">Manejar datos de Inscripciones Cursadas</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-acur">Manejar datos de Actas de Cursada</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-hic">Manejar datos de Historias Académicas de Cursada</NavDropdown.Item>
            </NavDropdown>                  
            <NavDropdown className="text-primary my-auto" style={{ width: '80px' }}  title="Examenes" >
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-mex">Manejar datos de Mesas de Examenes</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-ie">Manejar datos de Inscripciones a Examenes</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-aex">Manejar datos de Actas de Examenes</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-hex">Manejar datos de Historias de Examenes</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="text-primary my-auto" style={{ width: '80px' }} title="Períodos Académicos" >
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-anios">Manejar datos de Años Académicos</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-pex">Manejar datos de Períodos de Exámenes</NavDropdown.Item>
              <NavDropdown.Item style={{ width: '5vw' }} href="/md-pcr">Manejar datos de Períodos de Cursadas</NavDropdown.Item>
            </NavDropdown>    
          </Container>
        </Navbar>
      </header>
    );
  }
}
