import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Redirect } from 'react-router';
import '../Buttons.css';
import { Link } from 'react-router-dom';
import * as constantClass from '../fileWithConstants';
import '../Pagination.css';
import Select from 'react-select';

export class CargarActaCursada extends Component { /*MD= Manejar Datos*/
  static displayName = CargarActaCursada.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchData: [],
      searchInput: "",
      perPage: 10,
      paginationData: [],
      offset: 0,
      showErrorMessage: false,
      errorMessage: "",
      alumnos: [],
      materia: "",
      id: this.props.match.params.id,
      resultados: []
    };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return;
    else {
      this.deleteItem(id);
    }
  }

  handleConfirmButton = () => {
    const ff = this.allRowsWereSaved();
    const ggg = this.allDataPopulated();

    if (!ff || !ggg) {
      this.setState({ showErrorMessage: true, errorMessage: "Debe cargar todos los alumnos" });
    } else {
      this.setState({ showErrorMessage: false, errorMessage: "Debe cargar todos los alumnos" });
      this.postNotas();
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWActasCursado');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.alumnos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleChange = (e, id) => {
    const index = this.state.resultados.findIndex(x => x.id === e);

    if (index >= 0) {
      const element = this.state.resultados[index];
      element.resultado = id.value;
      this.state.resultados.splice(index, 1,element);
    } else {
      this.state.resultados.push({ id: e, resultado: id.value });
    }
  }

  allRowsWereSaved(){
    let todoOk = true;

    this.state.alumnos.forEach(y => {
      const index = this.state.resultados.findIndex(x => x.id === y.detalleActaCursadaCod);

      if (index < 0) {
        todoOk = false;
        return;
      }
    });

    return todoOk;
  }

  allDataPopulated() {
    let todoOk = true;

    this.state.resultados.forEach(x => {
      if (x.resultado === '') {
        todoOk = false;
        return;
      }
    })

    return todoOk;
  }

  renderalumnosTable(alumnos) {
    var options = [
      { value: '', label: 'Seleccione el resultado' },
      { value: 'R', label: 'Regular' },
      { value: 'L', label: 'Libre' }
    ];
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>DNI</th>
            <th>Nombre y Apellido</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          {alumnos.map(alumno =>
            <tr key={alumno.detalleActaCursadaCod}>
              <td>{alumno.alumnoDni}</td>
              <td>{alumno.alumnoNombre}</td>
              <td>{<Select
                options={options}
                disabled={true}
                placeholder="Seleccione el resultado"
                onChange={(e) => this.handleChange(alumno.detalleActaCursadaCod, e)}
              />}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.searchData);

    return (
      <div>
      {!this.state.success ?
         <div>
            <h1>Cargar Resultados de Acta de Cursada</h1>
            <div>
              {this.state.showErrorMessage &&
                <div>
                  <span className="labelError"> {this.state.errorMessage}</span>
                </div>
              }
          </div>
          <div>
            <h4>Materia: {this.state.materia}</h4>
            <div className="gsdgjasf">
                <ButtonGroup>
                  {!this.state.loading ? (
                    <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
                  ) : (
                    <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
                  )}
                  {!this.state.loading ? (
                    <Button size="sm" tag={Link} to={"/md-acur"} >Cancelar</Button>
                  ) : (
                    <Button size="sm">Cancelar</Button>
                  )}             
              </ButtonGroup>
            </div>
            <div>
              {contents}
            </div>
          </div>
      </div>
          : <Redirect to="/md-acur" />}
    </div >
    );
  }

  async populateData() {
    const response = await fetch('api/acur/detalle/'+this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.setState({
        alumnos: data.returnModel,
        materia: data.returnModel[0].materiaCarreraNombre.trim() + " - " +
          data.returnModel[0].carreraNombre.trim(),
        searchData: data.returnModel,
        loading: false
      });
    }
  }

  async postNotas() {
    this.setState({ loading: true });
    const objects = [];
    this.state.resultados.map((tag) => {
      objects.push({ DetalleActaCursadaCod: tag.id, DetalleActaCursadaResultado: tag.resultado});
    });
    const response = await fetch('api/acur',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify(objects)
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      window.alert("Acta cargada exitosamente");
      this.setState({ success: true });
    }
  }
}
