import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { LookupAnioAcademicos } from '../AnioAcademico/LookupAnioAcademicos';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

registerLocale('es', es)

export class UpdatePeriodoExamen extends Component {
  static displayName = UpdatePeriodoExamen.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      tagData: [{ id: "", text: "" }],
      startDate: new Date(),
      endDate: new Date(),
      llamado: "",
      anio: { anioAcademicoCod: 0, anioAcademicoDescripcion: "" },
      id: this.props.match.params.id,
      details: this.props.match.params.details
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWPeriodoExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      var errorMessage = "";
      var datesOk = this.areDatesOk();
      var isOkToSave = this.isOkToSave();

      if (!isOkToSave) {
        errorMessage = "Todos los campos son obligatorios";
        this.setState({ showErrorMessage: true });
      }

      if (!datesOk) {
        if (errorMessage.length > 0) {
          errorMessage = errorMessage + ", la fecha de inicio no puede ser posterior a la fecha de fin";
        } else {
          errorMessage = "La fecha de inicio no puede ser posterior a la fecha de fin"
        }
        this.setState({ showErrorMessage: true });
      }

      if (isOkToSave && datesOk) {
        this.saveStudent();
      } else {
        this.setState({ errorMessage: errorMessage });
      }
    }
  }

  async populateData() {
    const response = await fetch('api/pex/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    this.setState(
      {
        errorMessage: errorMessage,
        anio: {
          anioAcademicoCod: data.returnModel.anioAcademicoCod,
          anioAcademicoDescripcion: data.returnModel.anioAcademicoDescripcion
        },
        descripcion: data.returnModel.periodoExamenDescripcion.trim(),
        llamado: data.returnModel.periodoExamenLlamado.trim(),
        startDate: new Date(data.returnModel.periodoExamenFechaInicio),
        endDate: new Date(data.returnModel.periodoExamenFechaFin),
      });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleStartDate = (date) => {
    this.setState({ startDate: date });
  }

  handleEndDate = (date) => {
    this.setState({ endDate: date });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const anioProperties = e.split('%');
    this.setState({
      anio: {
        anioAcademicoCod: anioProperties[0],
        anioAcademicoDescripcion: anioProperties[1]
      }
    });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Período de Examen" : "Modificar Período de Examen";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Período de Examen: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Período de Examen"
                onChange={this.handleDescripcionChangeInput}
                disabled={this.state.loading || disable}
                value={this.state.descripcion}
                required
              />
            </div>
            <div className="pa2">
              <label> Llamado: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Llamado"
                onChange={this.handleLlamadoChangeInput}
                disabled={this.state.loading || disable}
                value={this.state.llamado}
                required
              />
            </div>
            <div>
              {!disable ? <Button color="link" onClick={this.handleShowAniosLookup}> Seleccione el Año Académico</Button> :
                <label> Datos del Año Académico </label>}
              {this.state.showLookupAnios ? <LookupAnioAcademicos selectItem={this.handleSelectAnioItem} hideLookup={this.handleHideLookup} /> : ""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.anio.anioAcademicoDescripcion}
              />
            </div>
            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Inicio: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.startDate}
                    onChange={this.handleStartDate}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div> :
                <div>
                  <label> Fecha de Inicio </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.startDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <div>
              {!disable ?
                <div>
                  <label> Seleccione la Fecha de Fin: </label>
                  <DatePicker className="gsfsafsFSFSf"
                    locale="es"
                    closeOnScroll={true}
                    selected={this.state.endDate}
                    onChange={this.handleEndDate}
                    disabled={this.state.loading}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                : <div>
                  <label> Fecha de Fin </label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                    name="name" size="30"
                    disabled={true}
                    value={this.state.endDate.toLocaleDateString()}
                  />
                </div>}
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-pex"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-pex" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.descripcion.length > 0 && this.state.llamado.length > 0 && this.state.anio != null && this.state.anio.anioAcademicoDescripcion.length > 0 && this.state.startDate != null && this.state.endDate != null);
  }

  areDatesOk() {
    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);

    return (endDate > startDate);
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/pex',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          PeridoExamenCod: parseInt(this.state.id),
          PeriodoExamenDescripcion: this.state.descripcion,
          PeriodoExamenLlamado: this.state.llamado,
          PeriodoExamenFechaInicio: this.state.startDate,
          PeriodoExamenFechaFin: this.state.endDate,
          AnioAcademicoCod: parseInt(this.state.anio.anioAcademicoCod)
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    } else { this.setState({ loading: false, success: success, errorMessage: errorMessage }); }
  }
}
