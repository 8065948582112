import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Pagination.css';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import { format } from "date-fns";

export class MDHistoriaAcademicaCursada extends Component { /*MD= Manejar Datos*/
  static displayName = MDHistoriaAcademicaCursada.name;

  constructor(props) {
    super(props);
    this.state = { loading: true, searchData: [], searchInput: "", perPage: 10, paginationData: [], offset: 0, showErrorMessage: false, errorMessage: "", showMessageCargando:false };
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getPeriodoCursada = this.getPeriodoCursada.bind(this);
    this.handleModificarEstado = this.handleModificarEstado.bind(this);
  }

  handleRemoveItem = (id) => {
    if (!window.confirm("¿Desea eliminar este registro?"))
      return; 
    else {
      this.deleteItem(id);
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('HistoriaAcademicaCursada');
    if (canAccess) {
      this.populateData();
    }
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    if (this.state.searchInput.length === 0) {
      var data = this.state.periodos.slice(offset, offset + this.state.perPage);

      this.setState({ paginationData: data });

    } else {
      var dataPagination = this.state.searchData.slice(offset, offset + this.state.perPage);
      this.setState({ paginationData: dataPagination, pageCount: Math.ceil(this.state.searchData.length / this.state.perPage) });
    }

  };

  handleChange = e => {
    if (e.target.value !== "") {
      this.setState({ searchInput: e.target.value, loading: true });
      const data = this.state.periodos.filter(
        periodo => {
          return (
            periodo
              .alumnoNombre
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) 
          )
        }
      );
      var paginationData = data.slice(0, 0 + this.state.perPage);
      this.setState({ searchData: data, loading: false, paginationData: paginationData, pageCount: Math.ceil(data.length / this.state.perPage) });
    } else {
      var paginationData = this.state.periodos.slice(0, 0 + this.state.perPage);
      this.setState({ paginationData: paginationData, pageCount: Math.ceil(this.state.periodos.length / this.state.perPage), searchInput: "" });
    }
  }

  handleModificarEstado(){
      this.actualizarRegularidades()
  }

  getPeriodoCursada(periodoCursadaDescripcion) {

    if (periodoCursadaDescripcion.trim() == "R") {
      return "Regular";
    } else {
      if (periodoCursadaDescripcion.trim() == "L") {
        return "Libre";
      }
    }
  }

  renderalumnosTable(periodos) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Alumno</th>
            <th>Carrera</th>
            <th>Materia</th>
            <th>Fecha de Cursada</th>
            <th>Resultado</th>
            <th>Fecha de Fin de Regularidad</th>
            <th>Observaciones</th>
            <th>Fecha de Vencimiento de Regularidad</th>
            <th colSpan="4">Acción</th>
          </tr>
        </thead>
        <tbody>
          {periodos.map(periodo =>
            <tr key={periodo.historiaAcademicaCursadaCod}>
              <td>{periodo.alumnoNombre}</td>
              <td>{periodo.carrera}</td>
              <td>{periodo.materia}</td>
              <td>{format(new Date(periodo.historiaAcademicaFecha),'dd/MM/yyyy')}</td>
              <td>{this.getPeriodoCursada(periodo.historiaAcademicaResultado)}</td>
              <td>{periodo.historiaAcademicaResultado.trim() == "R" ? format(new Date(periodo.historiaAcademicaFechaFinRegul), 'dd/MM/yyyy'): ""}</td>
              <td>{periodo.historiaAcademicaCursadaObserv}</td>
              <td>{(periodo.historiaAcademicaCursadaFechaV && periodo.historiaAcademicaCursadaFechaV != '1753-01-01T00:00:00')? format(new Date(periodo.historiaAcademicaCursadaFechaV),'dd/MM/yyyy'): ""}</td>
              <td>
                <ButtonGroup>
                  <Button className="editButton" size="sm" color="primary" tag={Link} to={"/update-hic/" + periodo.historiaAcademicaCursadaCod + "/0"} >Modificar</Button>
                  <Button className="editButton" size="sm" color="danger" onClick={() => this.handleRemoveItem(periodo.historiaAcademicaCursadaCod)}>Borrar</Button>
                  <Button className="editButton" size="sm" tag={Link} to={"/update-hic/" + periodo.historiaAcademicaCursadaCod + "/1"} >Detalles</Button>
                </ButtonGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Cargando datos...</em></p>
      : this.renderalumnosTable(this.state.paginationData);

    return (
      <div>
        <h1>Manejar Datos de Historias Académicas de Cursadas</h1>
        {this.state.showErrorMessage ?
          <div>
            <span className="labelError"> {"Ocurrió un error al intentar ejecutar la operación." + this.state.errorMessage}</span>
          </div> :
          <div>
            <div className="pa2">
              <p>
                <Link to="/accur">Actualizar Regularidades por Carrera</Link>
              </p>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                type="search"
                placeholder="Ingrese Nombre del Alumno "
                onChange={this.handleChange}
              />
            </div>
            {contents}
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>}

      </div>
    );
  }

  async populateData() {
    const response = await fetch('api/hac/Index', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      data.returnModel.sort((a, b) => b.historiaAcademicaCursadaCod - a.historiaAcademicaCursadaCod);
      const paginationData = data.returnModel.slice(0, this.state.perPage);
      this.setState({ periodos: data.returnModel, searchData: data.returnModel, loading: false, pageCount: Math.ceil(data.returnModel.length / this.state.perPage), paginationData: paginationData });
    }
  }

  async deleteItem(id) {
    this.setState({ loading: true });
    const response = await fetch('api/hac/delete/' + id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.populateData();
    }
  }

  async actualizarRegularidades() {
    this.setState({showMessageCargando:true});
    const response = await fetch('api/ic/actcur',
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    }
    this.setState({ showMessageCargando: false });
  }
}
