import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';
import '../Tags.css';
import { LookupAlumnos } from '../Alumnos/LookupAlumnos';
import { LookupCarreras } from '../Carreras/LookupCarreras';

export class AddAlumnoCarrera extends Component {
  static displayName = AddAlumnoCarrera.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      tagData: [{id:"",text:""}],
      alumno: { alumnoDni: "", alumnoCodigo: 0, alumnoNombre: "" },
      showLookupStudent: false,
      showLookupCarreras: false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelectStudentItem = this.handleSelectStudentItem.bind(this);
    this.handleSelectCarreraItem = this.handleSelectCarreraItem.bind(this);
    this.handleShowStudentLookup = this.handleShowStudentLookup.bind(this);
    this.handleShowCarreraLookup = this.handleShowCarreraLookup.bind(this);
    this.handleHideStudentLookup = this.handleHideStudentLookup.bind(this);
    this.handleHideCarreraLookup = this.handleHideCarreraLookup.bind(this);
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.saveStudent();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Los campos Alumno y Carrera son obligatorios" });
    }
  }

  async componentDidMount() {
    await constantClass.canAccess('WWAlumnosxCarrera');
  }

  handleSelectStudentItem = (e) =>
  {
    const studentProperties = e.split('%');
    this.setState(
      {
        alumno: {
          alumnoDni: studentProperties[0],
          alumnoNombre: studentProperties[1],
          alumnoCodigo: studentProperties[2]
        },
        showLookupStudent:false
      });
  }

  handleSelectCarreraItem = (e) =>
  {
    const carreraProperties = e.split('%');
    const tag = { id: carreraProperties[0], text: carreraProperties[1] }
    const tagData = this.state.tagData;
    tagData.push(tag);
    this.setState({showLookupCarreras: false, tagData:tagData});
  }

  rendleTags() {
    var tagData = this.state.tagData.slice(1);
    return (
      <div>
        {tagData.length > 0?
          tagData.map((tag) => (
          <li key={tag.id} className="tag">
            <span className="tag-title">{tag.text}</span>
            <span
              className="tag-close-icon"
              onClick={() =>this.handleDelete(tag.id)}
            >
              x
                     </span>
          </li>
        ))
        :""}
      </div>
    );
  }

  handleShowStudentLookup = () =>
  {
    this.setState({showLookupStudent:true});
  }

  handleHideStudentLookup = () =>
  {
    this.setState({showLookupStudent:false});  
  }

  handleHideCarreraLookup = () =>
  {
    this.setState({showLookupCarreras:false});  
  }

  handleShowCarreraLookup = () =>
  {
    this.setState({showLookupCarreras:true});
  }

  handleDelete = (i) => {
    this.setState({tagData:this.state.tagData.filter(tag =>tag.id !== i)});
  }

  render() {
    var content = this.rendleTags();
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Alumno en Carrera</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              {!this.state.loading ? <Button color="link" onClick={this.handleShowStudentLookup}> Seleccione el Alumno</Button> :
                <label> Seleccione el Alumno </label>}
              {this.state.showLookupStudent ? <LookupAlumnos selectStudent={this.handleSelectStudentItem} hideLookup={this.handleHideStudentLookup} />:""}
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.alumnoDni}
                required
              />
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                disabled={true}
                value={this.state.alumno.alumnoNombre}
                required
              />
              
            </div>
            <div>
              {!this.state.loading ? <Button color="link" onClick={this.handleShowCarreraLookup}> Seleccione la carrera</Button>
                  : <label> Seleccione la carrera </label>}
              {this.state.showLookupCarreras ? <LookupCarreras selectItem={this.handleSelectCarreraItem} hideLookup={this.handleHideCarreraLookup}/> : ""}
              <div className="tag-input">
                <ul className="tags">
                  {content}
                </ul>
              </div>
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading ? (
                <Button size="sm" tag={Link} to={"/md-axc"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-axc" />
        }
      </div>
    );
  }

  isOkToSave() {
    const tags = this.state.tagData.slice(1);
    return (this.state.alumno.alumnoCodigo.length > 0 && tags.length > 0);
  }

  async saveStudent() {
    var object = [];
    this.state.tagData.slice(1).map((tag) =>
    {
      object.push({ AlumnoCodigo: parseInt(this.state.alumno.alumnoCodigo), CarreraCod:parseInt(tag.id)});
    });
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/axc',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify(object)
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
