import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import { Redirect } from 'react-router';
import * as constantClass from '../fileWithConstants';
import Select from 'react-select';

const optionsCuatrimestre = [
  { value: '', label: '' },
  { value: 'S', label: 'Si' },
  { value: 'N', label: 'No' }
];

export class UpdateAlumno extends Component {
  static displayName = UpdateAlumno.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      descripcion: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      valueDictado: { value: '', label: '' }
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWAlumnos');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      if (this.isOkToSave()) {
        this.saveStudent();
      } else {
        this.setState({ showErrorMessage: true, errorMessage: "Los campos Documento y Nombre son obligatorios" });
      }
    }
  }

  handleDNIChangeInput = e => {
    this.setState({ dni: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ descripcion: e.value, valueDictado: e });
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Alumno" : "Modificar Alumno";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{ title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se están actualizando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Documento de Identidad: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese DNI"
                onChange={this.handleDNIChangeInput}
                disabled={this.state.loading||disable}
                value={this.state.dni}
                required
              />
            </div>
            <div className="pa2">
              <label> Nombre Completo: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre Completo"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading || disable}
                value={this.state.name}
                required
              />
            </div>
            {!disable ?
              <div>
                <label> Papeles Completos: </label>
                <div className="dropdownDictado">
                  <Select
                    value={this.state.valueDictado}
                    options={optionsCuatrimestre}
                    onChange={this.handleDictadoChangeInput}
                  />
                </div>
              </div>
              :
              <div>
                <label> Papeles Completos: </label>
                <input
                  className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                  name="name" size="10"
                  onChange={this.handleDescripcionChangeInput}
                  disabled={this.state.loading || disable}
                  value={this.state.descripcion}
                  required
                />
              </div>}
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-alumnos"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          : <Redirect to="/md-alumnos" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.dni.length > 0 && this.state.name.length > 0);
  }

  async populateData() {
    const response = await fetch('api/Students/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    var defaultValuePractica = optionsCuatrimestre.filter(x => x.value.toString().trim() === data.returnModel.alumnoPapelesCompletos.trim());
    this.setState({
      dni: data.returnModel.alumnoDni,
      name: data.returnModel.alumnoNombre,
      errorMessage: errorMessage,
      valueDictado: defaultValuePractica,
      descripcion: data.returnModel.alumnoPapelesCompletos
    })
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Students/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({ AlumnoNombre: this.state.name.trim(), AlumnoDni: this.state.dni.trim(), AlumnoCodigo: parseInt(this.state.id), AlumnoPapelesCompletos: this.state.descripcion })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage:errorMessage});
  }
}
