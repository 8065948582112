import { format } from "date-fns";
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

export class UpdateEncabezado extends Component {
  static displayName = UpdateEncabezado.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dni: "",
      name: "",
      descripcion: "",
      showErrorMessage: false,
      success: false,
      id: this.props.match.params.id,
      errorMessage: "",
      details: this.props.match.params.details,
      folio: "",
      libro: "",
      materia: "",
      tipoActa: "",
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDNIChangeInput = this.handleDNIChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleDictadoChangeInput = this.handleDictadoChangeInput.bind(this);
    this.handleOnChangeFolio = this.handleOnChangeFolio.bind(this);
    this.handleOnChangeLibro = this.handleOnChangeLibro.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWActasExamen');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.isOkToSave()) {
      this.postEncabezado();
    } else {
      this.setState({ showErrorMessage: true, errorMessage: "Debe ingresar el libro y el folio" });
    }
  }

  handleDNIChangeInput = e => {
    this.setState({ dni: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleDictadoChangeInput = e => {
    this.setState({ descripcion: e.value, valueDictado: e });
  }

  handleOnChangeFolio = (e) => {
    this.setState({ folio: e.target.value });
  }

  handleOnChangeLibro = (e) => {
    this.setState({ libro: e.target.value });
  }

  render() {
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Ver Acta de Examen</h1>
            <div>
              {this.state.showErrorMessage &&
                <div>
                  <span className="labelError"> {this.state.errorMessage}</span>
                </div>
              }
            </div>
            <div>
              <h4>Materia: {this.state.materia}</h4>
              <h6>Tipo Acta: {this.state.tipoActa == 'R' ? "Regular" : this.state.tipoActa == 'L' ? "Libre" : this.state.tipoActa == 'E' ? "Equivalencia" : ""}</h6>
              <h6>Fecha Examen: {format(new Date(), 'dd/MM/yyyy')}</h6>
              <div>
                <div className="side-by-side">
                  <label>Libro:</label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 actaInput"
                    name="name" size="30"
                    value={this.state.libro}
                    disabled={this.state.loading}
                    onChange={this.handleOnChangeLibro}
                  />
                  <label className="labelInput">Folio:</label>
                  <input
                    className="pa3 bb br3 grow b--none bg-lightest-blue ma3 actaInput"
                    name="name" size="30"
                    value={this.state.folio}
                    disabled={this.state.loading}
                    onChange={this.handleOnChangeFolio}
                  />
                </div>
              </div>
              <div className="gsdgjasf">
                <ButtonGroup>
                  {!this.state.loading ? (
                    <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
                  ) : (
                    <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
                  )}
                </ButtonGroup>
              </div>
            </div>
          </div>
          : <Redirect to="/md-aex" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.libro != "" && this.state.folio != "");
  }

  async populateData() {
    const response = await fetch('api/aex/detalle/' + this.state.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.setState({
        folio: data.returnModel[0].actaExamenFolio ? data.returnModel[0].actaExamenFolio : "",
        libro: data.returnModel[0].actaExamenLibro ? data.returnModel[0].actaExamenLibro : "",
        materia: data.returnModel[0].materiaCarreraNombre.trim() + " - " +  data.returnModel[0].carreraNombre.trim(),
        fechaExamen: data.returnModel[0].mesaExamenFechaExamen,
        tipoActa: data.returnModel[0].actaExamenTipo,
        searchData: data.returnModel,
        loading: false
      });
    }
  }

  async postEncabezado() {
    this.setState({ loading: true });
    const response = await fetch('api/aex/encabezado',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          ActaExamenFolio: this.state.folio,
          ActaExamenLibro: this.state.libro,
          ActaExamenNacta: this.state.id
        })
      });
    const data = await response.json();
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var errorMessage = data.errorMessage ? data.errorMessage : "Forbidden.";
      this.setState({ showErrorMessage: true, errorMessage: errorMessage });
    } else {
      this.setState({ success: true });
    }
  }
}
