import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import Select from 'react-select';
import '../Buttons.css';
import * as constantClass from '../fileWithConstants';

const options = [
  {value: '',label:''},
  { value: 'S',label: 'Si' },
  { value: 'N', label: 'No' }
];

export class UpdateCarrera extends Component {
  static displayName = UpdateCarrera.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      id: this.props.match.params.id,
      details: this.props.match.params.details,
      anioImplementacion: "",
      name: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      resolucionMinisterial: "",
      extinta: "",
      value: { value: '', label: '' }
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleAnioImplementacion = this.handleAnioImplementacion.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleResolucionMinisterial = this.handleResolucionMinisterial.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('WWCarreras');
    if (canAccess) {
      this.populateData();
    }
  }

  handleConfirmButton = () => {
    if (this.state.details === "1") {
      this.setState({ success: true })
    } else {
      if (this.isOkToSave()) {
        this.saveCarrera();
      } else {
        this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
      }
    }
  }

  handleAnioImplementacion = e => {
    this.setState({ anioImplementacion: e.target.value });
  }

  handleResolucionMinisterial = e => {
    this.setState({ resolucionMinisterial: e.target.value });
  }

  handleNameChangeInput = e => {
    this.setState({ name: e.target.value });
  }

  handleSelect = e =>
  {
    this.setState({extinta:e.value,value:e});
  }

  render() {
    var disable = this.state.details === "0" ? false : true;
    var title = disable ? "Ver datos de Carrera" : "Modificar Carrera";
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>{ title}</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div className="pa2">
              <label> Nombre Completo: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Nombre Completo"
                onChange={this.handleNameChangeInput}
                disabled={this.state.loading || disable}
                value={this.state.name}
                required
              />
            </div>
            <div className="pa2">
              <label> Año de Implementación: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 DNIButton"
                name="name" size="30"
                placeholder="Ingrese Año Implementación"
                onChange={this.handleAnioImplementacion}
                disabled={this.state.loading || disable}
                value={this.state.anioImplementacion}
              />
            </div>
            <div className="pa2">
              <label> Resolución Ministerial: </label>
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3 body"
                name="name" size="30"
                placeholder="Ingrese Resolución de Aprobación"
                onChange={this.handleResolucionMinisterial}
                disabled={this.state.loading || disable}
                value={this.state.resolucionMinisterial}
                required
              />
            </div>
            <div className="div">
              <label> Carrera Extinta: </label>
              {disable ?
                <div className="label">
                  <label> {this.state.extinta == 'N'? 'No':'Si' }</label>
                </div>:
                <div className="dropdown">
                  <Select
                    value={this.state.value}
                    options={options}
                    onChange={this.handleSelect}
                  />
                </div>
              }
            </div>
            <ButtonGroup>
              {!this.state.loading ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !disable ? (
                <Button size="sm" tag={Link} to={"/md-carreras"} >Cancelar</Button>
              ) : ("")}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-carreras" />}
      </div>
    );
  }

  isOkToSave() {
    return (this.state.anioImplementacion.length > 0 && this.state.name.length > 0 && this.state.resolucionMinisterial.length > 0, this.state.extinta.length >0);
  }

  async populateData() {
    const response = await fetch('api/Carreras/' + this.state.id,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        }
      });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
    }
    var defaultValue = options.filter(x => x.value.toString() === data.returnModel.carreraExtinta);
    this.setState({
      anioImplementacion: data.returnModel.carreraAnioImplementacion,
      name: data.returnModel.carreraNombre.trim(),
      extinta: data.returnModel.carreraExtinta,
      resolucionMinisterial: data.returnModel.carreraResolucionAprobacion.trim(),
      errorMessage: errorMessage,
      value: defaultValue
    });
  }

  async saveCarrera() {
    this.setState({ showErrorMessage: false, loading: true });
    const response = await fetch('api/Carreras/',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          CarreraNombre: this.state.name,
          CarreraAnioImplementacion: this.state.anioImplementacion,
          CarreraResolucionAprobacion: this.state.resolucionMinisterial,
          CarreraExtinta: this.state.extinta,
          CarreraCod: parseInt(this.state.id)
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
