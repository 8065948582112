import es from 'date-fns/locale/es';
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, ButtonGroup } from 'reactstrap';
import '../Buttons.css';
import { LookupCursadasAlumnos } from '../Cursadas/LookupCursadasAlumnos';
import * as constantClass from '../fileWithConstants';

registerLocale('es', es)

export class AddInscripcionCursadaAlumnos extends Component {
  static displayName = AddInscripcionCursadaAlumnos.name;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      descripcion: "",
      showErrorMessage: false,
      success: false,
      errorMessage: "",
      showLookupAnios: false,
      showCursada: false,
      tagDataAlumno: [{ value: '', label: '' }],
      tagDataCursada: [{ id: "", text: "", materiaCarreraCod3:"" }],
      alumno: 0,
      cursada: { codigo: "", descripcion: "" },
      carreraCursada: -1,
      materiaCodigo: "",
      ignorarCorrelatividad: false,
      error:false
    };
    this.handleConfirmButton = this.handleConfirmButton.bind(this);
    this.handleDescripcionChangeInput = this.handleDescripcionChangeInput.bind(this);
    this.handleLlamadoChangeInput = this.handleLlamadoChangeInput.bind(this);
    this.handleShowAniosLookup = this.handleShowAniosLookup.bind(this);
    this.handleSelectAnioItem = this.handleSelectAnioItem.bind(this);
    this.handleHideLookup = this.handleHideLookup.bind(this);
    this.handleShowCursadaLookup = this.handleShowCursadaLookup.bind(this);
    this.handleSelectCursadaItem = this.handleSelectCursadaItem.bind(this);
    this.handleHideLookup1 = this.handleHideLookup1.bind(this);
    this.handleChangeAlumno = this.handleChangeAlumno.bind(this);
  }

  handleConfirmButton = () => {
    var isOkToSave = this.isOkToSave();

    if (!isOkToSave) {
      this.setState({ showErrorMessage: true, errorMessage: "Todos los campos son obligatorios" });
    } else {
      this.saveStudent();
    }
  }

  async componentDidMount() {
    const canAccess = await constantClass.canAccess('InscribirCursada');
    if (canAccess) {
      this.populateData();
    }
  }

  handleChangeAlumno = (e) => {
    const carreraProperties = e.value.toString().split('-');    
    this.setState({ alumno: parseInt(carreraProperties[1]), carreraCursada: parseInt(carreraProperties[0]), tagDataCursada: [{ id: "", text: "", materiaCarreraCod3: "" }] });
  }

  handleDelete = (i) => {
    this.setState({ tagDataAlumno: this.state.tagDataAlumno.filter(tag => tag.id !== i), carreraCursada: -1 });
  }

  handleDeleteCursada = (i) => {
    this.setState({ tagDataCursada: this.state.tagDataCursada.filter(tag => tag.id !== i) });
  }

  handleShowAniosLookup = () => {
    this.setState({ showLookupAnios: true });
  }

  handleShowCursadaLookup = () => {
    this.setState({ showCursada: true });
  }

  handleHideLookup = () => {
    this.setState({ showLookupAnios: false });
  }
  handleHideLookup1 = () => {
    this.setState({ showCursada: false });
  }

  handleDescripcionChangeInput = e => {
    this.setState({ descripcion: e.target.value });
  }

  handleLlamadoChangeInput = e => {
    this.setState({ llamado: e.target.value });
  }

  handleSelectAnioItem = (e) => {
    const carreraProperties = e.split('%');
    this.setState({
      showLookupAnios: false,
      carreraCursada: Number(carreraProperties[3]),
      alumno: {
        codigo: carreraProperties[0],
        descripcion: carreraProperties[1] + " - " + carreraProperties[2]
      }
    });
  }

  handleSelectCursadaItem = (e) => {
    const carreraProperties = e.split('%');
    const tag = {
      id: carreraProperties[0],
      text: carreraProperties[1].trim() + " - " + carreraProperties[2].trim() + " - " + carreraProperties[4].trim(),
      materiaCarreraCod3: carreraProperties[5].trim()
    }
    const tagData = this.state.tagDataCursada;
    const existe = tagData.filter(x => x.id == carreraProperties[0]);
    if (existe.length == 0) {
      tagData.push(tag);
      this.setState({
        showCursada: false,
        tagDataCursada: tagData
      });
    } else {
      this.setState({
        showCursada: false
      });
    }
  }

  rendleTags() {
    var tagData = this.state.tagDataCursada.slice(1);
    return (
      <div>
        {tagData.length > 0 ?
          tagData.map((tag) => (
            <li key={tag.id} className="tag">
              <span className="tag-title">{tag.text}</span>
              <span
                className="tag-close-icon"
                onClick={() => this.handleDeleteCursada(tag.id)}
              >
                x
              </span>
            </li>
          ))
          : ""}
      </div>
    );
  }

  render() {
    var content = this.rendleTags();
    return (
      <div>
        {!this.state.success ?
          <div>
            <h1>Nuevo Inscripción a Cursada</h1>
            <span className="labelError"> {this.state.errorMessage.length > 0 && !this.state.loading ? this.state.errorMessage : ""}</span>
            <span className="labelMessage"> {this.state.loading ? "Los datos se está guardando, espere unos segundos..." : ""}</span>
            <div>
              <label> Seleccione la carrera: </label>
              <Select
                placeholder={'Seleccione la carrera'}
                options={this.state.tagDataAlumno}
                disabled={this.state.loading}
                onChange={this.handleChangeAlumno}
              />
            </div>
            <div>
              {!this.state.loading && this.state.carreraCursada != -1 ? <Button color="link" onClick={this.handleShowCursadaLookup}> Seleccione la Cursada</Button>
                : <label> Seleccione la carrera para poder seleccionar la materia </label>}
              {this.state.showCursada ? <LookupCursadasAlumnos alumnoCarreraCod={this.state.alumno} carreraCod={this.state.carreraCursada} selectItem={this.handleSelectCursadaItem} hideLookup={this.handleHideLookup1} /> : ""}
              <div className="tag-input">
                <ul className="tags">
                  {content}
                </ul>
              </div>
            </div>
            <ButtonGroup>
              {!this.state.loading && !this.state.error ? (
                <Button className="editButton" size="sm" color="primary" onClick={() => this.handleConfirmButton()} >Confirmar</Button>
              ) : (
                <Button className="editButton" size="sm" color="primary" onClick={() => { }}>Confirmar</Button>
              )}
              {!this.state.loading && !this.state.error ? (
                <Button size="sm" tag={Link} to={"/md-ica"} >Cancelar</Button>
              ) : (
                <Button size="sm">Cancelar</Button>
              )}
            </ButtonGroup>
          </div>
          :
          <Redirect to="/md-ica" />}
      </div>
    );
  }

  isOkToSave() {
    const tags = this.state.tagDataCursada.slice(1);
    return (tags.length > 0 && this.state.alumno != 0);
  }

  async populateData() {
    const response = await fetch('api/axc/inscripcion/' + Cookies.get('alumnoCodigos'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': constantClass.AuthorizationHeader,
        'Username': constantClass.ReturnUserName()
      }
    });
    let data = await response.json();
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = error;
    }
    var options = [];
    data.returnModel.sort((a, b) => a.carreraCod - b.carreraCod);
    for (var i = 0; i < data.returnModel.length;i++)
    {
      options.push({ value: data.returnModel[i].carreraCod + " - " + data.returnModel[i].alumnoxCarreraCod, label: data.returnModel[i].carreraNombre  });
    }
    this.setState(
      {
        errorMessage: errorMessage,
        tagDataAlumno: options
      });
  }

  async saveStudent() {
    this.setState({ showErrorMessage: false, loading: true });
    var object = [];
    this.state.tagDataCursada.slice(1).map((tag) => {
      object.push({ Cursada: parseInt(tag.id), MateriaCarreraCod3: tag.materiaCarreraCod3 });
    });
    const response = await fetch('api/ic/multiple',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': constantClass.AuthorizationHeader,
          'Username': constantClass.ReturnUserName()
        },
        body: JSON.stringify({
          AlumnoxCarreraCod: this.state.alumno,
          IgnorarCorrelatividad: this.state.ignorarCorrelatividad,
          Cursadas: object
        })
      });
    const data = await response.json();
    var success = true;
    var errorMessage = "";
    if (data == "Timeout") {
      constantClass.RedirectToLogin();
    } else if (data.errorMessage || data == "Forbidden.") {
      var error = data.errorMessage ? data.errorMessage : "Forbidden.";
      errorMessage = "Ocurrió un error al ejecutar la operación: " + error;
      success = false;
    }
    this.setState({ loading: false, success: success, errorMessage: errorMessage });
  }
}
